import React, { useEffect, useState } from "react";
import Layout from "../../layouts/default";
import { toast } from "react-toastify";
import FilterForm from "./FilterForm";
import TableList from "../../components/TableList";
import download from "../../assets/images/download.png";
import serviceAgreement from "../../services/serviceAgreement"; // Import the service
import "./ServiceAgreement.css";
import { checkPermission, formatDate } from "../../helpers/common";
import { useSelector } from "react-redux";

export const ServiceAgreement = () => {
  const handleDownloadClick = async (e, rowData) => {
    e.preventDefault();
    const dataToSend = {
      filepath: rowData?.filepath,
      filename: `${rowData?.tradeName}_service_agreement.pdf`,
      source: "service_agreement",
    };

    console.log("Formatted Data:", dataToSend);
    try {
      await serviceAgreement.downloadAgreement(dataToSend);
    } catch (error) {
      console.error("Error in handleDownloadClick:", error);
    }
  };

  const { permission } = useSelector((state) => state.defaulterSlice);
  const thead = [
    "Sr. No.",
    "Account Name",
    "GST Number",
    "Agreement Sign Date/Time",
    "Director Name",
    "Aadhar Name",
    "Agreement View",
    "Reason",
    "Action",
  ];

  const handleActionChange = async (e, rowData) => {
    e.preventDefault();
    const previousValue ="pending";
    console.log("DATA IS", rowData);
    const selectedValue = e.target.value;
    const confirmed = window.confirm(
      "Are you sure you want to Change Agreement Status ?"
    );
    if (confirmed) {
      console.log("VAL", selectedValue);
      if (selectedValue == "verified") {
        const dataToSend = {
          uuid: rowData.uuid,
          filePath: rowData.filepath,
          userId: rowData.userId,
        };
        const res = serviceAgreement.changeStatusofAgreement(dataToSend);
        if (res) {
          toast.success("Status is Changed Successfully Verified");
          await fetchData();
          setFilterData(defaultFilterData);
        }
      }

      if (selectedValue == "Rejected") {
        const dataToSend = {
          uuid: rowData.uuid,
          filePath: rowData.filepath,
          userId: rowData.userId,
          status: "REJECTED",
        };
        const res = serviceAgreement.updateAgreementStatus(dataToSend);
        if (res) {
          toast.success("Status is Changed Successfully Rejected");
          await fetchData();
          setFilterData(defaultFilterData);
        }
      }
      e.target.value = previousValue;
    }
    else{
      e.target.value = previousValue;
    }
  };

  const dbFieldsForTd = [
    { field: "accountName" },
    { field: "gstNumber" },
    { field: "agreementDate" },
    { field: "directorName" },
    { field: "aadharName" },
    {
      field: "agreementView",
      callback: (id, rowData) =>
        checkPermission(
          permission?.data,
          "Service Agreement List Msme",
          "download"
        ) === 1 ? (
          <a href="#" onClick={(e) => handleDownloadClick(e, rowData)}>
            <img src={download} width={"15%"} alt="Download" />
          </a>
        ) : (
          "No Permission"
        ),
    },
    { field: "reason" },
    {
      field: "action",
      callback: (id, rowData) =>
        checkPermission(
          permission?.data,
          "Service Agreement List Msme",
          "add_edit"
        ) === 1 ? (
          <select
            className="custom-dropdown"
            onChange={(e) => handleActionChange(e, rowData)}
          >
            <option value="pending">Pending</option>
            <option value="verified">Verified</option>
            <option value="Rejected">REJECTED</option>
          </select>
        ) : (
          <span>No Permission</span>
        ),
    },
  ];

  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState({});
  const [page, setPage] = useState(1);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    recordsPerPage: 10,
  });

  const defaultFilterData = {
    defaulter_gstn: "DoNotShowList",
  };

  const fieldsConfig = [
    { name: "account_name", label: "Account Name", required: false },
    { name: "gst_number", label: "GST Number", required: false },
    { name: "director_name", label: "Director Name", required: false },
  ];

  const cleanFilterData = (data) => {
    const cleanedData = {};
    Object.keys(data).forEach((key) => {
      if (data[key] && data[key] !== "DoNotShowList") {
        cleanedData[key] = data[key];
      }
    });
    return cleanedData;
  };
  const tableActions = [];
  const fetchData = async () => {
    setLoading(true);
    try {
      const cleanedFilterData = cleanFilterData(filterData);

      const requestData = {
        ...cleanedFilterData,
        page: paginationData.page,
        recordsPerPage: paginationData.recordsPerPage,
      };
      const result = await serviceAgreement.getListofPending(requestData);
      console.log("API Response:", result);
      if (result.status) {
        // Map API data to the structure expected by the table
        const mappedData = result.data.map((item, index) => ({
          id: item.id,
          uuid: item.uuid,
          accountName: item.user_name,
          tradeName: item.trade_name,
          gstNumber: item.gst_no,
          agreementDate: formatDate(item.signed_date),
          directorName: item.director_name,
          aadharName: item.aadhaar_name || "N/A",
          filepath: item.filepath,
          reason: item.reason || "NAME MISMATCH",
          business_id: item.business_id,
          userId: item.userId,
        }));
        setFullData(mappedData);
      } else {
        toast.error("Failed to fetch data.");
      }
    } catch (error) {
      toast.error("Error fetching data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    console.log("DATAfilterData ", filterData);
  }, [page, filterData]);

  return (
    <Layout heading="Service Agreement">
      <FilterForm
        setLoading={setLoading}
        setFilterData={setFilterData}
        defaultFilterData={defaultFilterData}
        fieldsConfig={fieldsConfig}
      />
      <br />
      <TableList
        thead={thead}
        dbFieldsForTd={dbFieldsForTd}
        fullData={fullData}
        loading={loading}
        tableActions={tableActions}
        paginationData={paginationData}
      />
    </Layout>
  );
};

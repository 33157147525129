import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Table } from "react-bootstrap";
import PaginationList from "../../../components/Pagination";

export const BlockBusinessList = (props) => {
  const {
    loading,
    setLoading,
    setPage,
    page,
    onPaginationClick,
    businessList,
  } = props;

  const navigate = useNavigate();
  //Heading of the table
  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Trade Name</th>
          <th>Legal Name</th>
          <th>GST No/PAN No</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>Status</th>
          <th>Created At</th>
          <th>Action</th>
        </tr>
      </thead>
    );
  };

  const _list = () => {
    if (!businessList?.data || businessList?.data.length < 1) {
      return (
        <tr>
          <td colSpan="9">No record found!!</td>
        </tr>
      );
    }
    return businessList.data.map((item, index) => {
      const sNo = page
        ? page === 0
          ? index + 1
          : 10 * (page - 1) + (index + 1)
        : index + 1;
      return (
        <tr key={item.id}>
          <td>{sNo}</td>
          <td>{item.trade_name}</td>
          <td>{item.company_name}</td>
          <td>{item?.gst_no ? item?.gst_no : item?.pan_no}</td>
          <td>{item.mobile}</td>
          <td>{item.email}</td>
          <td>{item.customers_status.replace(/_/g, " ")}</td>
          <td>{format(new Date(item?.date_created), "dd-MM-yyyy")}</td>
          <td>
            {item.customers_status !== "inactive" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 cursor-pointer"
                style={{ width: "25px", height: "auto", cursor: "pointer" }}
                onClick={() => detailPageHandler(item.id)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            ) : (
              "N/A"
            )}
          </td>
        </tr>
      );
    });
  };

  const detailPageHandler = (id) => {
    const currentTimeStamp = new Date().getTime();
    const encodedId = btoa(`${currentTimeStamp}-${id}`);
    navigate(`/smartSearch/businessDetail/${encodedId}`);
    console.log("detailPageHandler@@@@@@", encodedId);
  };

  const paginationClickHandler = (pageNumber) => {
    console.log("paginationData@@@@@clicked", pageNumber);
    if (onPaginationClick) {
      onPaginationClick(pageNumber);
    }
  };

  console.log("business detail@@@@@pagination", businessList);

  return (
    <>
      <div
        style={{
          maxWidth: "100%",
          "overflow-x": "scroll",
          "white-space": "nowrap",
        }}
      >
        {loading ? (
          <div style={{ minHeight: "45VH" }}>Processing...</div>
        ) : (
          <Table striped bordered hover>
            {_tableHeader()}
            <tbody>{_list()}</tbody>
          </Table>
        )}
      </div>

      {businessList?.total > businessList?.pagination?.recordsPerPage && (
        <PaginationList
          paginationData={businessList?.pagination}
          setPage={setPage}
          modulePath="app"
          onPageClick={paginationClickHandler}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import CustomStyle from "./../../styles/customStyle";
import Modal from "react-modal";
import { format } from "date-fns";
import Classes from "./style.module.css";
import { useForm } from "react-hook-form";
import { Icons, toast } from "react-toastify";
import * as Icon from "react-bootstrap-icons";
import { getToken } from "../../helpers/common";

import defaultersService from "../../services/payments/index";

export const CallRequestHoldList = (props) => {
  const { callRequests, defaulter_id, defaulter_case_id, source } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState("");
  const [paymentProofFileBase64, setPaymentProofFileBase64] = useState("");
  const [paymentProofFileName, setPaymentProofFileName] = useState("");
  const [isDetailsOpen,setIsDetailsOpen] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const methodType = watch("methodType");

  useEffect(() => {
    console.log("setSelectedEntrysetSelectedEntry: ", selectedEntry);
  }, [selectedEntry]);

  useEffect(() => {
    reset({
      methodType,
      file: "",
      comment: "",
    });
    setPaymentProofFileBase64("");
    setPaymentProofFileName("");
  }, [methodType, reset]);

  const onSubmit = async (data) => {
    if (paymentProofFileBase64 && paymentProofFileName) {
      data.file = paymentProofFileBase64;
      data.fileName = paymentProofFileName;
    }
    data.chrId = selectedEntry?.id;
    console.log("Form Submitted:", data);
    const result = await defaultersService.releaseWalletHoldPoints(data);
    if (result.status === 200) {
      toast.success("Wallet hold points released successfully");
      setIsOpen(false);
      reset();
      window.location.reload();
      return;
    } else {
      toast.error(result.message);
      return;
    }
  };

  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>Hold Type</th>
          <th>Hold From</th>
          <th>Hold To</th>
          <th>Installment Amount</th>
          <th>created By</th>
          <th>Date Created</th>
          <th>Status</th>
          <th>Actions</th>
          <th>Details</th>
        </tr>
      </thead>
    );
  };
  const _selectFiles = async (e) => {
    var fileToLoad = e.target.files[0];
    var fileReader = new FileReader();
    var base64 = [];
    fileReader.onload = function (fileLoadedEvent) {
      base64.push(fileLoadedEvent.target.result);
      setPaymentProofFileBase64(base64);
      setPaymentProofFileName(
        `Payment-proof_from_${selectedEntry.defaulter_trade_name}_to_${selectedEntry.supplier_trade_name}`
      );
    };
    if (fileToLoad?.size <= 2000000) {
      fileReader.readAsDataURL(fileToLoad);
    } else {
      toast.error("File is too large.");
    }
  };

  const _itemsList = () => {
    if (!callRequests?.data?.response.length) {
      return (
        <tr>
          <td colSpan="8">No record found!!</td>
        </tr>
      );
    }
    console.log(
      "################payment",
      callRequests.data.response
    );
    return callRequests.data.response.map((item, index) => {
      const latestCustomerPortalHoldTo = Math.max(
        ...callRequests.data.response
          .filter(
            (i) =>
              i.source === "CUSTOMER_PORTAL" &&
              i.wallet_points_released === "NO"
          )
          .map((i) => new Date(i.hold_to).getTime())
      );
      // console.log("service charge item list", item);
      return (
        <tr key={index}>
          <td>{item.type_for_hold}</td>
          <td>{item.hold_from}</td>
          <td>{item.hold_to}</td>
          <td>{item.installment_amount}</td>
          <td>{item.name + `(` + item.mobile + `)`}</td>
          <td>{format(new Date(item.date_created), "yyyy-MM-dd")}</td>
          <td>{item.request_status}</td>
          {item.source === "CUSTOMER_PORTAL" ? (
            <td>
              {" "}
              {new Date(item.hold_to).getTime() ===
                latestCustomerPortalHoldTo &&
                 (
                  <button
                    className="btn btn-small btn-theme btn btn-primary"
                    onClick={() => {
                      setSelectedEntry(item);
                      setIsOpen(true);
                    }}
                  >
                    Action
                  </button>
                )}
            </td>
          ) : (
            <>
              <td className={Classes.actions}>
                <Link
                  to={`/holdRequest/${item.type_for_hold}/${defaulter_case_id}/edit/${item.id}/${source}`}
                >
                  {item.request_status === "pending" ? "Edit" : "View"}
                </Link>
              </td>
            </>
          )}

          {(item.document_path || item.comment) &&
            item.source ===
              "CUSTOMER_PORTAL" && (
                <td>
                  <Icon.AppIndicator
                    onClick={() => {
                      setSelectedEntry(item);
                      setIsDetailsOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Action
                  </Icon.AppIndicator>
                </td>
              )}
        </tr>
      );
    });
  };
  const downloadHandler = () => {
    let token = getToken();
    // let fileExtension = callRequestDetails[0].document_path.split(".").pop();
    let payload = {
      filename: `payment_proof_from_${selectedEntry.defaulter_trade_name}_to_${selectedEntry.supplier_trade_name}`,
      filepath: selectedEntry.document_path,
    };
    console.log("download payload########", payload);
    defaultersService.downloadPaymentProof(payload, token);
  };
  return (
    <>
      <Table className="striped bordered  hover">
        {_tableHeader()}
        <tbody>{_itemsList()}</tbody>
      </Table>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={CustomStyle}
        setAppElement={"addContact"}
        contentLabel="Action Modal"
      >
        <span className="close" onClick={() => setIsOpen(false)}>
          &times;
        </span>
        <h4>RELEASE HOLD POINTS</h4>
        {selectedEntry.wallet_points_released === "NO" && (
          <div className="form-box-mobile">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ marginTop: "5px" }}>
                <div style={{ display: "flex" }}>
                  <label style={{ width: "108%" }}>
                    SETTLEMENT NOT COMPLETED
                  </label>
                  <input
                    type="radio"
                    value="settlementNotCompleted"
                    style={{ height: "20px" }}
                    {...register("methodType", {
                      required: "Please select an admin type.",
                    })}
                  />
                </div>
                <div style={{ display: "flex", marginTop: "15px" }}>
                  <label style={{ width: "108%" }}>SETTLEMENT COMPLETED</label>
                  <input
                    type="radio"
                    value="settlementCompleted"
                    style={{ height: "20px", marginLeft: "-50px" }}
                    {...register("methodType", {
                      required: "Please select a specific method.",
                    })}
                  />
                </div>
              </div>
              {errors.methodType && (
                <span className="error-message">
                  {errors.methodType.message}
                </span>
              )}
              {methodType === "settlementCompleted" && (
                <div>
                  <label>Upload File:</label>
                  <input
                    type="file"
                    {...register("file", {
                      required: "File upload is required",
                    })}
                    onChange={(event) => {
                      _selectFiles(event);
                    }}
                  />
                  {errors.file && (
                    <p className="error">{errors.file.message}</p>
                  )}
                </div>
              )}
              <div>
                <label>Comment:</label>
                <textarea
                  rows="3"
                  {...register("comment", {
                    required: methodType === "settlementNotCompleted" && {
                      value: true,
                      message: "Comment is required",
                    },
                    minLength: {
                      value: 5,
                      message: "Comment must be at least 5 characters",
                    },
                  })}
                  placeholder="Enter your comment"
                />
                {errors.comment && (
                  <p className="error">{errors.comment.message}</p>
                )}
              </div>
              <Button
                type="submit"
                className="btn btn-theme btn btn-primary mt-3"
              >
                Release Hold Points
              </Button>
            </form>
          </div>
        )}
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={isDetailsOpen}
        onRequestClose={() => setIsDetailsOpen(false)}
        style={CustomStyle}
        setAppElement={"addContact"}
        contentLabel="Action Modal"
      >
        <span className="close" onClick={() => setIsDetailsOpen(false)}>
          &times;
        </span>
        <h4>RELEASE HOLD POINTS LOG</h4>
        <>
          <div className="form-box-mobile">
            {selectedEntry?.document_path && (
              <Button variant="outline-secondary" onClick={downloadHandler}>
                Download Payment Proof
              </Button>
            )}
            {selectedEntry?.comment && (
              <>
                <label>Comment</label>
                <p>{selectedEntry?.comment}</p>
              </>
            )}
          </div>
        </>
      </Modal>
    </>
  );
};
export default CallRequestHoldList;

import { createBrowserHistory } from "history";
import DOMPurify from "dompurify";
export const history = createBrowserHistory();
export const getToken = () => {
  const userAuth = localStorage.getItem("user");
  //console.log("getToken", userAuth);
  const token = userAuth ? JSON.parse(userAuth).data?.token : "";
  return token;
};

export const currencyFormatter = (value, showSymbol = true, pre = true) => {
  let data = "";

  if (value) {
    data = Number(value)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    if (showSymbol) {
      data = pre ? `₹ ${data}` : `${data} ₹`;
    }
  }
  return data;
};
export const addDays = (dateStr, days) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + days);
  return date.toISOString().split('T')[0];
};
export const checkPermission = (permissions, title, property) => {
  if (!permissions || !Array.isArray(permissions)) {
    return undefined;
  }
  const permissionEntry = permissions.find(
    (permission) => permission.title === title
  );
  return permissionEntry ? permissionEntry[property] : undefined;
};

export const capitalizeAllLetters = (text) => {
  return text.toUpperCase();
};
export const sanitizeInput = (value) => {
  if (typeof value === "string") {
    return DOMPurify.sanitize(value.trim());
  }
  return value;
};
export const formatDate = (dateString) => {
  const [datePart, timePart] = dateString.split("T");
  const [year, month, day] = datePart.split("-");
  const [hours, minutes] = timePart.split(":");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
import React from "react";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import { useSelector } from "react-redux";

export const BlockActivityJourneyLogList = (props) => {
  const { activityList, onLoadMore, page } = props;
  const { smartSearchUserActivity } = useSelector(
    (state) => state.smartSearchUserDetailSlice
  );

  const loadMoreActivity = () => {
    console.log("load more activity.......");
    const newPage = page + 1;
    onLoadMore(newPage);
  };
  //Heading of the table
  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Activity</th>
          <th>Created At</th>
        </tr>
      </thead>
    );
  };

  console.log("activityList@@@@@result", activityList);

  const _list = () => {
    if (!activityList.status) {
      return (
        <tr>
          <td colSpan="3">No record found!!</td>
        </tr>
      );
    }
    return smartSearchUserActivity.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{index + 1}</td>
          <td>{item?.activity ? item.activity : ""}</td>
          <td>
            {item?.activity_date
              ? format(new Date(item?.activity_date), "dd-MM-yyyy h:mm:ss a")
              : ""}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div>
        <Table striped bordered hover>
          {_tableHeader()}
          <tbody>{_list()}</tbody>
        </Table>
        {activityList?.total &&
          activityList?.total > activityList?.limit &&
          smartSearchUserActivity.length <= activityList?.total && (
            <div className="text-center">
              <span
                className="textunderline cursor-pointer inline-block"
                onClick={loadMoreActivity}
              >
                Load More
              </span>
            </div>
          )}
      </div>
    </>
  );
};

import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import Layout from "../../../layouts/default";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BuyerSnippet from "../../../components/Defaulter/BuyerSnippet";
import SupplierSnippet from "../../../components/Defaulter/SupplierSnippet";
import { getPermission } from "../../../store/slices/defaulter";
import { getPayment } from "../../../store/slices/creditqDefaulters/payments";
import { getDefaulter } from "../../../store/slices/creditqDefaulters/index.js";
import { clearMessage } from "../../../store/slices/message";
import { useNavigate } from "react-router-dom";
import { supplierWalletBalance } from "../../../store/slices/walletBalance";
import {
  capitalizeAllLetters,
  sanitizeInput,
  getToken,
} from "../../../helpers/common";
import * as apiService from "../../../services/api";
import * as API_URL from "../../../constants/api";
import EmailSupplierModal from "../Modal/EmailSupplierModal.js";
import EmailAuthorityModal from "../Modal/EmailAuthorityModal.js";
import RejectModal from "../Modal/RejectModal.js";
import ModalStyles from "../../../components/ModalStyles.js";

const CQPaymentCreate = () => {
  const { type, defaulter_id } = useParams();
  const [defaulterData, setDefaulterData] = useState("");
  const [paymentAmountToHold, setPaymentAmountToHold] = useState(0);
  const { creditqDefaulter } = useSelector(
    (state) => state.creditqDefaultersSlice
  );
  const { wallet_balance } = useSelector(
    (state) => state.SupplierWalletBalanceSlice
  );
  const { paymentDetails } = useSelector((state) => state.paymentTypeSlice);
  const dispatch = useDispatch();
  const [paymentProofFileBase64, setPaymentProofFileBase64] = useState("");
  const [paymentProofFileName, setPaymentProofFileName] = useState("");
  const [dueAmount, setDueAmount] = useState(0);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [legalCaseValue, setLegalCaseValue] = useState("");
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [timeoutRemaining, setTimeoutRemaining] = useState(0);
  const [values, setValues] = useState({
    defaulterId: defaulter_id,
    settlementType: type,
    initialApproverEmailId: "",
    transaction_date: "",
    legalCase: "",
    courtName: "",
    courtStage: "",
    caseDetails: "",
    caseJudgement: "",
    receivedAmount: "",
    remark: "",
    // evidence: "",
    paymentProof: "",
    paymentProofName: "",
    paymentProofFile: "",
    ...(type === "full" ? { writeOff: dueAmount } : { dueAmount: dueAmount }),
  });
  const [isSpModalOpen, setIsSpModalOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [settlementData, setSettlementData] = useState(null);

  const validateAndSetTouched = async (validateForm, setTouched, values) => {
    const errors = await validateForm();

    setTouched({
      initialApproverEmailId: true,
      receivedAmount: true,
      transaction_date: true,
      remark: true,
      paymentProof: true,
      legalCase: true,
      ...(legalCaseValue === "yes" && {
        courtName: true,
        courtStage: true,
        caseDetails: true,
        caseJudgement: true,
      }),
    });

    if (type === "full" || type === "remove") {
      values.writeOff = dueAmount;
    } else {
      {
        values.dueAmount = dueAmount;
      }
    }
    setValues(values);

    return errors;
  };

  const handleSpEmailButton = async (validateForm, setTouched, values) => {
    try {
      const errors = await validateAndSetTouched(
        validateForm,
        setTouched,
        values
      );

      if (Object.keys(errors).length === 0) {
        setIsSpModalOpen(true);
      }
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const closeSpModal = () => {
    setIsSpModalOpen(false);
  };

  const handleAuthEmailButton = async (validateForm, setTouched, values) => {
    try {
      const errors = await validateAndSetTouched(
        validateForm,
        setTouched,
        values
      );

      if (Object.keys(errors).length === 0) {
        setIsAuthModalOpen(true);
      }
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const closeAuthModal = () => {
    setIsAuthModalOpen(false);
  };

  const handleRejectButton = () => {
    setIsRejectModalOpen(true);
  };
  const closeRejectModal = () => {
    setIsRejectModalOpen(false);
  };
  useEffect(() => {
    if (paymentDetails && paymentDetails.data)
      setPaymentData(paymentDetails?.data);
  }, [paymentDetails]);

  useEffect(() => {
    dispatch(clearMessage());
    const fetchDefaulter = async (id) => {
      if (id > 0) {
        let filterData = { id: Number(id) };
        dispatch(getDefaulter({ filterData }));
      }
    };
    fetchDefaulter(defaulter_id);
  }, [defaulter_id, dispatch]);
  useEffect(() => {
    if (creditqDefaulter && creditqDefaulter.data)
      setDefaulterData(creditqDefaulter?.data.defaulter);
    console.log(
      "creditqDefaulter?.data.defaulter: ",
      creditqDefaulter?.data?.defaulter
    );
  }, [creditqDefaulter]);

  useEffect(() => {
    dispatch(getPayment(defaulter_id));
  }, [dispatch, defaulter_id]);
  useEffect(() => {
    if (defaulterData?.payment_due_amount) {
      const calculatedDueAmount =
        defaulterData.payment_due_amount -
        defaulterData.settled_amount -
        receivedAmount;
      setDueAmount(calculatedDueAmount > 0 ? calculatedDueAmount : 0);
    }
  }, [receivedAmount, defaulterData]);

  const filteredPaymentData = useMemo(() => {
    let latestSettlementType = [];
    if (Array.isArray(paymentData)) {
      const sortedEntries = [...paymentData]
        .filter((entry) => entry.source !== "customer")
        .sort((a, b) => b.id - a.id)
        .slice(0, 1);
      latestSettlementType = sortedEntries.length > 0 ? sortedEntries[0] : null;
    }
    return latestSettlementType;
  }, [paymentData]);

  useEffect(() => {
    if (filteredPaymentData) {
      const { l1_approval_status, final_approval_status, settlement_type } =
        filteredPaymentData;
      if (
        l1_approval_status === "PENDING" ||
        (l1_approval_status === "APPROVED" &&
          final_approval_status === "pending") ||
        (settlement_type === "full" && final_approval_status === "approved")
      ) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  }, [filteredPaymentData]);
  useEffect(() => {
    if (defaulterData) {
      const filterData = {
        supplier_user_id: defaulterData?.supplier_user_id,
        active_balance: true,
        inactive_balance: true,
      };
      dispatch(supplierWalletBalance(filterData));
    }
  }, [dispatch, defaulterData]);

  const fetchSettlementRequestData = async () => {
    try {
      const token = getToken();
      const response = await apiService.get(
        API_URL.GET_SETTLEMENT_REQUEST_BY_ADMIN + defaulter_id,
        token
      );
      if (response.status === 200 && response.data.email_supplier === 1) {
        const settlementData = response.data;
        setSettlementData(settlementData);
        console.log("Fetched Settlement Data:", settlementData);

        const formatDate = (isoDate) => {
          if (!isoDate) return "";
          const date = new Date(isoDate);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };

        const updatedValues = {
          defaulterId: defaulter_id,
          settlementType: type,
          ...(type === "full" || type === "partial"
            ? { receivedAmount: settlementData?.amount }
            : { receivedAmount: 0 }),
          initialApproverEmailId: settlementData?.initial_approver_email || "",
          transaction_date: formatDate(settlementData?.transaction_date) || "",
          legalCase: settlementData?.legal_case?.toLowerCase() || "",
          courtName: settlementData?.court_name || "",
          courtStage: settlementData?.court_stage?.toLowerCase() || "",
          caseDetails: settlementData?.case_details || "",
          caseJudgement: settlementData?.case_judgement || "",
          remark: settlementData?.comment || "",
          paymentProofName: settlementData?.paymentProofName || "",
          paymentProofFile: settlementData?.paymentProofFile || "",
          ...(type === "full"
            ? { writeOff: settlementData?.write_off }
            : { dueAmount: dueAmount }),
        };

        setValues(updatedValues);

        setLegalCaseValue(settlementData?.legal_case?.toLowerCase() || "");
      }
      if (response.status === 200 && response.data.email_supplier !== 1) {
        const settlementData = response.data;
        setSettlementData(settlementData);
        console.log(settlementData);
      }
    } catch (error) {
      console.error("Error fetching settlement request data:", error);
    }
  };

  useEffect(() => {
    fetchSettlementRequestData();
  }, [defaulter_id]);

  useEffect(() => {
    const storedTimeoutEnd = localStorage.getItem(
      `emailTimeoutEnd_${defaulter_id}`
    );
    if (storedTimeoutEnd) {
      const remainingTime = Math.max(
        0,
        Math.floor((new Date(storedTimeoutEnd) - new Date()) / 1000)
      );
      if (remainingTime > 0) {
        setIsEmailDisabled(true);
        setTimeoutRemaining(remainingTime);

        const interval = setInterval(() => {
          setTimeoutRemaining((prevTime) => {
            if (prevTime <= 1) {
              clearInterval(interval);
              setIsEmailDisabled(false);
              localStorage.removeItem(`emailTimeoutEnd_${defaulter_id}`);
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
      } else {
        localStorage.removeItem(`emailTimeoutEnd_${defaulter_id}`);
      }
    }
  }, []);

  useEffect(() => {
    if (
      ["approved", "rejected"].includes(
        settlementData?.final_approval_status
      ) ||
      settlementData?.agent_reject === 1
    ) {
      setIsEmailDisabled(false);
      localStorage.removeItem(`emailTimeoutEnd_${defaulter_id}`);
      setTimeoutRemaining(0);
    }
  }, [settlementData]);

  const handleEmailSent = (emailType) => {
    toast.success("Email sent successfully!");
    fetchSettlementRequestData();
    if (emailType !== "emailAuthority") {
      setIsEmailDisabled(true);
      setTimeoutRemaining(15 * 60);
      const timeoutEnd = new Date(new Date().getTime() + 15 * 60 * 1000);

      localStorage.setItem(
        `emailTimeoutEnd_${defaulter_id}`,
        timeoutEnd.toISOString()
      );

      const interval = setInterval(() => {
        setTimeoutRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(interval);
            setIsEmailDisabled(false);
            localStorage.removeItem(`emailTimeoutEnd_${defaulter_id}`);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
  };

  const validationSchema = Yup.object().shape({
    remark: Yup.string().trim().required().label("Remark"),
    legalCase: Yup.string()
      .oneOf(["yes", "no", ""], "Invalid legal case status")
      .required("Legal Case Status is required")
      .label(
        "Is there are legal case in court is already running related to above information"
      ),
    courtName: Yup.string().when("legalCase", {
      is: "yes",
      then: Yup.string().required("Court Name is required"),
      otherwise: Yup.string().notRequired(),
    }),
    courtStage: Yup.string().when("legalCase", {
      is: "yes",
      then: Yup.string().required("Court Stage is required"),
      otherwise: Yup.string().notRequired(),
    }),
    caseDetails: Yup.string().when("legalCase", {
      is: "yes",
      then: Yup.string().required("Case Details are required"),
      otherwise: Yup.string().notRequired(),
    }),
    caseJudgement: Yup.string().when("legalCase", {
      is: "yes",
      then: Yup.string().required("Case Judgement is required"),
      otherwise: Yup.string().notRequired(),
    }),
    initialApproverEmailId: Yup.string()
      .trim()
      .required()
      .email()
      .label("Email ID"),
    receivedAmount:
      type !== "remove"
        ? Yup.number()
            .typeError("Amount must be a valid number")
            .required("Amount is required")
            .when([], {
              is: () => type === "partial",
              then: (schema) => schema.min(1, "Amount must be at least 1"),
            })
            .max(
              defaulterData?.payment_due_amount - defaulterData.settled_amount,
              `Amount cannot be greater than ${
                defaulterData.payment_due_amount - defaulterData.settled_amount
              }`
            )
            .label("Amount")
        : Yup.number().notRequired(),
    // paymentProof: Yup.mixed().required().label("Evidence"),
    transaction_date:
      type !== "remove" && Yup.date().required("Transaction date is required"),
  });
  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file && file.size <= 2000000) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPaymentProofFileBase64(reader.result);
        setPaymentProofFileName(file.name);
        setFieldValue("paymentProof", file);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("File is too large to get processed");
    }
  };

  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    setIsLoading(true);
    const sanitizedValues = {
      defaulterId: defaulter_id,
      settlementType: type !== "remove" ? type : "removed",
      initialApproverEmailId: sanitizeInput(values.initialApproverEmailId),
      receivedAmount: sanitizeInput(
        type !== "remove" ? values.receivedAmount : 0
      ),
      remark: sanitizeInput(values.remark),
      caseJudgement: sanitizeInput(values.caseJudgement),
      caseDetails: sanitizeInput(values.caseDetails),
      courtName: sanitizeInput(values.courtName),
      paymentProofName: sanitizeInput(values.paymentProofName),
      transaction_date: values.transaction_date,
      paymentProofFile: values.paymentProofFile,
      ...(type === "full" || type === "remove"
        ? { writeOff: dueAmount }
        : { dueAmount: dueAmount }),
    };
    let token = getToken();

    const result = await apiService.post(
      API_URL.UPDATE_PAYMENT_FOR_CREDITQ_DEFAULTER,
      sanitizedValues,
      token
    );
    if (result.status === 200) {
      setIsLoading(false);
      toast.success(result.message);
      navigate(`/creditq-defaulters/${defaulter_id}`);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Layout heading={`Settlement Page - ${capitalizeAllLetters(type)}`}>
        <div>
          <Row className="mb-5">
            <Col>
              <BuyerSnippet defaulter={defaulterData} />
            </Col>
            <Col>
              <SupplierSnippet
                defaulter={defaulterData}
                wallet_balance={wallet_balance?.data}
              />
            </Col>
          </Row>
          {
            <Formik
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                if (values.paymentProof !== "") {
                  values.paymentProofName = paymentProofFileName;
                  values.paymentProofFile = paymentProofFileBase64;
                } else {
                  values.paymentProof = "";
                }
                handleSubmit(values);
              }}
              validationSchema={validationSchema}
              initialValues={values}
            >
              {({
                values,
                isSubmitting,
                errors,
                touched,
                setFieldValue,
                setTouched,
                validateForm,
              }) => (
                <>
                  <Form>
                    <div className="form-editable-section">
                      <Row>
                        <Col>
                          {defaulterData && (
                            <div className="form-box-mobile icon-box">
                              <label>
                                {capitalizeAllLetters("Total Amount")}
                              </label>
                              <p>
                                {defaulterData?.payment_due_amount
                                  ? defaulterData.payment_due_amount
                                  : ""}
                              </p>
                            </div>
                          )}
                        </Col>
                        <Col>
                          {type === "full" || type === "remove" ? (
                            <div className="form-box-mobile icon-box">
                              <label>{capitalizeAllLetters("Write off")}</label>
                              <p>{dueAmount}</p>
                            </div>
                          ) : type === "partial" ? (
                            <div className="form-box-mobile icon-box">
                              <label>
                                {capitalizeAllLetters("balance Amount")}
                              </label>
                              <p>{dueAmount}</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>

                      {type !== "partial" && (
                        <Row>
                          <Col>
                            {defaulterData && (
                              <div className="form-box-mobile icon-box">
                                <label>
                                  {capitalizeAllLetters("settled amount")}
                                </label>
                                <p>
                                  {defaulterData?.settled_amount
                                    ? defaulterData.settled_amount
                                    : ""}
                                </p>
                              </div>
                            )}
                          </Col>
                          <Col>
                            <div className="form-box-mobile icon-box">
                              <label>
                                {capitalizeAllLetters("balance amount")}
                              </label>
                              <p>
                                {Number(
                                  defaulterData?.payment_due_amount -
                                    defaulterData?.settled_amount
                                )}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col>
                          <div className="form-box-mobile icon-box">
                            <label>
                              {capitalizeAllLetters("Payment Due Date")}
                            </label>
                            {defaulterData?.payment_due_date ? (
                              <p>
                                {format(
                                  new Date(defaulterData?.payment_due_date),
                                  "d MMMM, yyyy"
                                )}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </Col>
                        <Col>
                          <div className="form-box-mobile icon-box userInput">
                            <label>
                              {capitalizeAllLetters("Approving Authority")}
                            </label>
                            <Field
                              name="initialApproverEmailId"
                              onChange={(e) => {
                                setFieldValue(
                                  "initialApproverEmailId",
                                  e.target.value
                                );
                              }}
                              placeholder="Email ID"
                            />
                            {errors.initialApproverEmailId &&
                              touched.initialApproverEmailId && (
                                <div className="error">
                                  {errors.initialApproverEmailId}
                                </div>
                              )}
                          </div>
                        </Col>
                      </Row>
                      {type !== "remove" && (
                        <Row>
                          <Col>
                            <div className="form-box-mobile icon-box userInput">
                              <label>
                                {capitalizeAllLetters("settlement Amount")}
                              </label>
                              <Field
                                name="receivedAmount"
                                onChange={(e) => {
                                  setFieldValue(
                                    "receivedAmount",
                                    e.target.value
                                  );
                                  setReceivedAmount(e.target.value);
                                }}
                                placeholder="In Rupees"
                              />
                              {errors.receivedAmount &&
                                touched.receivedAmount && (
                                  <div className="error">
                                    {errors.receivedAmount}
                                  </div>
                                )}
                            </div>
                          </Col>

                          <Col>
                            <div className="form-box-mobile icon-box userInput">
                              <label htmlFor="transaction_date">
                                {capitalizeAllLetters("transaction date")}
                              </label>
                              <Field
                                name="transaction_date"
                                type="date"
                                className="form-control"
                                placeholder="Transaction date"
                                size="sm"
                                // value={(transaction_date)}
                                onChange={(e) => {
                                  setFieldValue(
                                    "transaction_date",
                                    e.target.value
                                  );
                                }}
                                max={format(new Date(), "yyyy-MM-dd")}
                                min={
                                  defaulterData &&
                                  format(
                                    new Date(defaulterData?.verified_date),
                                    "yyyy-MM-dd"
                                  )
                                }
                              />{" "}
                              {errors.transaction_date &&
                                touched.transaction_date && (
                                  <div className="error">
                                    {errors.transaction_date}
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <div className="form-box-mobile icon-box userInput">
                            <label>
                              {capitalizeAllLetters(
                                "Is there are legal case in court is already running related to above information"
                              )}
                            </label>
                            <select
                              className="form-select"
                              style={{
                                height: "40px",
                                borderColor: "#007bff",
                              }}
                              value={values.legalCase || ""}
                              onChange={(e) => {
                                setFieldValue("legalCase", e.target.value);
                                setLegalCaseValue(e.target.value);
                                if (e.target.value === "no") {
                                  setFieldValue("courtName", "");
                                  setFieldValue("courtStage", "");
                                  setFieldValue("caseDetails", "");
                                  setFieldValue("caseJudgement", "");
                                }
                              }}
                            >
                              <option value="">Select</option>
                              <option value="yes">YES</option>
                              <option value="no">NO</option>
                            </select>
                            {errors.legalCase && touched.legalCase && (
                              <div className="error">{errors.legalCase}</div>
                            )}
                          </div>
                        </Col>
                        <Col></Col>
                      </Row>
                      {legalCaseValue === "yes" && (
                        <>
                          <Row>
                            <Col>
                              <div className="form-box-mobile icon-box userInput">
                                <label>
                                  {capitalizeAllLetters("Court Name")}
                                </label>
                                <Field
                                  name="courtName"
                                  placeholder="Court Name"
                                  onChange={(e) => {
                                    setFieldValue("courtName", e.target.value);
                                  }}
                                />
                                {errors.courtName && touched.courtName && (
                                  <div className="error">
                                    {errors.courtName}
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col>
                              <Col>
                                <div className="form-box-mobile icon-box userInput">
                                  <label>
                                    {capitalizeAllLetters("Court Stage")}
                                  </label>
                                  <select
                                    className="form-select"
                                    style={{
                                      height: "40px",
                                      borderColor: "#007bff",
                                    }}
                                    value={values.courtStage || ""}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "courtStage",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="">Select</option>
                                    <option value="decided">DECIDED</option>
                                    <option value="pending">PENDING</option>
                                  </select>
                                  {errors.courtStage && touched.courtStage && (
                                    <div className="error">
                                      {errors.courtStage}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="form-box-mobile icon-box userInput">
                                <label>
                                  {capitalizeAllLetters("Case Details")}
                                </label>
                                <Field
                                  name="caseDetails"
                                  component="textarea"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "caseDetails",
                                      e.target.value
                                    );
                                  }}
                                />
                                {errors.caseDetails && touched.caseDetails && (
                                  <div className="error">
                                    {errors.caseDetails}
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="form-box-mobile icon-box userInput">
                                <label>
                                  {capitalizeAllLetters("Case Judgement")}
                                </label>
                                <Field
                                  name="caseJudgement"
                                  component="textarea"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "caseJudgement",
                                      e.target.value
                                    );
                                  }}
                                />
                                {errors.caseJudgement &&
                                  touched.caseJudgement && (
                                    <div className="error">
                                      {errors.caseJudgement}
                                    </div>
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row>
                        <Col>
                          <div className="form-box-mobile icon-box userInput">
                            <label>{capitalizeAllLetters("Remark")}</label>
                            <Field
                              name="remark"
                              onChange={(e) => {
                                setFieldValue("remark", e.target.value);
                              }}
                              component="textarea"
                            />
                            {errors.remark && touched.remark && (
                              <div className="error">{errors.remark}</div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                        <div className="form-box-mobile icon-box userInput">
                          <label>{capitalizeAllLetters("Evidence")}</label>
                          <input
                            type="file"
                            id="fileUpload"
                            className="file-input"
                            onChange={(event) =>
                              handleFileChange(event, setFieldValue)
                            }
                            hidden
                          />
                          <label
                            style={{
                              border: "1px solid #007bff",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            htmlFor="fileUpload"
                            className="btn btn-upload"
                          >
                            {paymentProofFileName || "Choose File"}
                          </label>
                          {errors.paymentProof && touched.paymentProof && (
                            <div className="error">{errors.paymentProof}</div>
                          )}
                        </div>
                      </Row> */}
                      {defaulterData?.supplier_has_lwb === 1 && (
                        <span
                          className="blinking-text"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          WARNING: Supplier has low wallet balance{" "}
                        </span>
                      )}

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {settlementData?.email_authority !== 1 && (
                            <div>
                              <button
                                className="btn btn-theme btn-small mlauto mt20"
                                type="button"
                                onClick={() => {
                                  handleSpEmailButton(
                                    validateForm,
                                    setTouched,
                                    values
                                  );
                                }}
                                disabled={
                                  isEmailDisabled ||
                                  isSubmitting ||
                                  (settlementData?.final_approval_status ===
                                    "approved" &&
                                    settlementData?.settlement_type ===
                                      "full") ||
                                  (settlementData?.settlement_type ===
                                    "removed" &&
                                    settlementData?.final_approval_status ===
                                      "approved") ||
                                  (settlementData?.email_supplier === 1 &&
                                    settlementData?.settlement_type !== type)
                                }
                              >
                                {isEmailDisabled
                                  ? `Resend in ${Math.floor(
                                      timeoutRemaining / 60
                                    )}:${(timeoutRemaining % 60)
                                      .toString()
                                      .padStart(2, "0")}`
                                  : "EMAIL SUPPLIER"}
                              </button>
                            </div>
                          )}
                          <div>
                            <button
                              className="btn btn-theme btn-small mlauto mt20"
                              type="button"
                              onClick={() => {
                                handleAuthEmailButton(
                                  validateForm,
                                  setTouched,
                                  values
                                );
                              }}
                              disabled={
                                (settlementData?.email_authority === 1 &&
                                  settlementData?.final_approval_status ===
                                    "pending") ||
                                (settlementData?.final_approval_status ===
                                  "approved" &&
                                  settlementData?.settlement_type === "full") ||
                                (settlementData?.settlement_type ===
                                  "removed" &&
                                  settlementData?.final_approval_status ===
                                    "approved") ||
                                (settlementData?.email_supplier === 1 &&
                                  settlementData?.settlement_type !== type)
                              }
                            >
                              EMAIL AUTHORITY
                            </button>
                          </div>
                          {settlementData?.email_supplier === 1 && (
                            <div>
                              <button
                                className="btn btn-theme btn-small mlauto mt20"
                                type="button"
                                onClick={() => {
                                  handleRejectButton();
                                }}
                                disabled={
                                  settlementData?.email_supplier === 1 &&
                                  settlementData?.settlement_type !== type
                                }
                              >
                                REJECT
                              </button>
                            </div>
                          )}
                        </div>
                        {settlementData?.email_supplier === 1 && (
                          <div>
                            <p
                              style={{
                                color: "green",
                                fontWeight: "bold",
                                marginTop: "1rem",
                              }}
                            >
                              The supplier has been notified via email, for
                              settlement type:{" "}
                              {capitalizeAllLetters(
                                settlementData?.settlement_type
                              )}
                            </p>
                          </div>
                        )}
                        {settlementData?.agent_reject === 1 && (
                          <div>
                            <p
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                marginTop: "1rem",
                              }}
                            >
                              The previous settlement request has been rejected
                              based on the supplier's response, for settlement
                              type:
                              {capitalizeAllLetters(
                                settlementData?.settlement_type
                              )}
                            </p>
                          </div>
                        )}
                        {/* {!disable ? (
                          <div>
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-theme btn-small mlauto mt20"
                            >
                              Settle
                            </button>
                          </div>
                        ) : (
                          <p style={{ color: "red" }}>Request is in process</p>
                        )} */}
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          }
        </div>
      </Layout>
      <Modal
        isOpen={isSpModalOpen}
        onRequestClose={true}
        style={ModalStyles}
        contentLabel="Email Supplier"
      >
        <>
          <div>
            <button
              className="close"
              onClick={closeSpModal}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#000",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: 0,
                height: 0,
                marginBottom: "12px",
              }}
            >
              &#10005;
            </button>
          </div>
          <EmailSupplierModal
            setIsModalOpen={setIsSpModalOpen}
            formValues={values}
            settlementData={settlementData}
            onEmailSent={handleEmailSent}
            setIsLoading={setIsLoading}
            defaulterID={defaulter_id}
          />
        </>
      </Modal>
      <Modal
        isOpen={isAuthModalOpen}
        onRequestClose={true}
        style={ModalStyles}
        contentLabel="Email Authority"
      >
        <>
          <div>
            <button
              className="close"
              onClick={closeAuthModal}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#000",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: 0,
                height: 0,
                marginBottom: "12px",
              }}
            >
              &#10005;
            </button>
          </div>
          <EmailAuthorityModal
            setIsModalOpen={setIsAuthModalOpen}
            formValues={values}
            settlementData={settlementData}
            onEmailSent={handleEmailSent}
            defaulterID={defaulter_id}
            setIsLoading={setIsLoading}
          />
        </>
      </Modal>
      <Modal
        isOpen={isRejectModalOpen}
        onRequestClose={true}
        style={ModalStyles}
        contentLabel="Reject"
      >
        <>
          <div>
            <button
              className="close"
              onClick={closeRejectModal}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#000",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: 0,
                height: 0,
                marginBottom: "12px",
              }}
            >
              &#10005;
            </button>
          </div>
          <RejectModal
            setIsModalOpen={setIsRejectModalOpen}
            formValues={values}
            settlementData={settlementData}
            onEmailSent={handleEmailSent}
            defaulterID={defaulter_id}
            setIsLoading={setIsLoading}
          />
        </>
      </Modal>
      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};

export default CQPaymentCreate;

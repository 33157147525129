import React, { useEffect, useState } from "react";
import Layout from "../../layouts/default";
import { clearMessage } from "../../store/slices/message";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDefaulter, getPermission } from "../../store/slices/defaulter";
import { getSupplier } from "../../store/slices/supplier";
import { currencyFormatter } from "../../helpers/common";
import { SettlementForm } from "./SettlementForm";
import { Col, Row, Card, Button } from "react-bootstrap";
import DefaulterContacts from "../../components/Defaulter/Contacts";
import { format } from "date-fns";
import SettlementPaymentPaidTo from "../../components/SettlementPayment/PaidTo";
import { useNavigate } from "react-router-dom";
import { createPaymentActions } from "../../store/slices/payment";
import SettlementPaymentList from "../../components/SettlementPayment/List";
import { getPayments } from "../../store/slices/payment";
import { getCallHoldRequest } from "../../store/slices/payment";
import { dateDifference } from "../../services/Misc";
import CallRequestHold from "../../components/CallRequestHold/initaiteRequest";
import { CallRequestHoldList } from "../../components/CallRequestHold/List";
import { getToken } from "../../helpers/common";
import defaultersService from "../../services/payments/";
import { supplierWalletBalance } from "../../store/slices/walletBalance";
import { toast } from "react-toastify";

export const Defaulter = () => {
  let defaulterData = null;

  const { message } = useSelector((state) => state.messageSlice);
  const { defaulters } = useSelector((state) => state.defaultersSlice);
  const { defaulter } = useSelector((state) => state.defaulterSlice);
  const { supplier } = useSelector((state) => state.supplierSlice);
  const { payments } = useSelector((state) => state.paymentSlice);
  const { permission } = useSelector((state) => state.defaulterSlice);
  const { callRequests } = useSelector((state) => state.paymentSlice);
  const [refresh, setRefresh] = useState(false);
  const [permissionToCreate, setPermissionToCreate] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [downloadMsg, setDownloadMsg] = useState("");
  const { wallet_balance } = useSelector(
    (state) => state.SupplierWalletBalanceSlice
  );

  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(createPaymentActions.resetnewPaymentAdded());
    const fetchDefaulter = async (id, defaulters) => {
      if (id > 0) {
        let filterData = { id: Number(id) };
        dispatch(getDefaulter({ filterData }));
        dispatch(getPermission());
      }
    };
    fetchDefaulter(id, defaulters);
  }, [dispatch, id, defaulters, refresh]);

  useEffect(() => {
    const fetchSupplier = async (id, defaulters) => {
      if (id > 0) {
        let filterData = { id: Number(id) };
        dispatch(getSupplier({ filterData }));
      }
    };

    fetchSupplier(id, defaulters);
  }, [dispatch, id, defaulters, refresh]);

  useEffect(() => {
    let payload = { query: { defaulter_case_id: id } };
    dispatch(getPayments(payload));
  }, [dispatch, id]);

  useEffect(() => {
    let payload = {
      query: { defaulter_case_id: defaulter?.data?.defaulter?.defaulter_id },
    };

    if (defaulter?.data?.defaulter?.defaulter_id) {
      dispatch(getCallHoldRequest(payload));
    }
  }, [dispatch, defaulter, refresh]);

  if (defaulter) {
    console.log("defaulter.data?.defaulter#####", defaulter.data?.defaulter);
    if (defaulter.data) defaulterData = defaulter.data?.defaulter;
  }

  useEffect(() => {
    if (defaulterData) {
      const filterData = {
        supplier_user_id: defaulterData?.supplier_user_id,
        active_balance: false,
        inactive_balance: true,
      };
      dispatch(supplierWalletBalance(filterData));
    }
  }, [dispatch, defaulterData]);

  const paidTochangeHandler = (paidTo) => {
    console.log("paid to change - ", paidTo);
    //setShowPayModal(true)
    navigate(`/payment/${paidTo}/${id}/create`);
  };

  const callRequestHoldHandler = (holdType) => {
    try {
      console.log("holdType to change - ", holdType);
      navigate(`/callHold/${holdType}/${id}/create/msme`);
    } catch (error) {
      console.log("Error in callRequestHoldHandler", error);
    }
  };
  console.log("checking the supplier_details", supplier);
  const downloadHandlerAgreement = () => {
    let fileExtension;
    let filenameAgreement;
    let token = getToken();
    if (supplier && supplier.data && supplier.data.defaulter) {
      let agreementDocPath = supplier.data.defaulter.agreement_document;
      console.log("checking match----agreementDocPath", agreementDocPath);
      const match = agreementDocPath.match(/\/([^\/]+)$/);
      console.log("checking match", match);
      // Check if a match was found
      if (match) {
        filenameAgreement = match[1];
        console.log(filenameAgreement);
      }
    }
    fileExtension = `agreement.pdf`;
    let payload = {
      source: "msme-defaulter",
      filename: fileExtension,
      filepath: filenameAgreement,
    };
    console.log("download payload########", payload);
    defaultersService.downloadAgreement(payload, token);
    setDownloadMsg("Agreement Download successfully!");
  };
  const downloadHandlerLedger = () => {
    let token = getToken();
    let filePath;
    if (supplier && supplier.data && supplier.data.defaulter) {
      filePath = supplier.data.defaulter.latest_ledger_path;
    }

    let postJson = { filename: "ledger.pdf", fileName: filePath };
    defaultersService.downloadLedger(postJson, token);
    setDownloadMsg("Ledger Download successfully!");
  };

  const isAnyPaymentRequestPending = () => {
    let isPending = false;
    if (payments?.data?.response && payments?.data.response.length > 0) {
      console.log(
        "shouldDisable#################okkkkkkkkkkkkk 63 -",
        payments?.data?.response
      );
      for (let i = 0; i < payments.data.response.length; i++) {
        let payment = payments.data.response[i];
        console.log(
          "shouldDisable########mmmmmmmmmmmmmmm status -",
          payment.status
        );
        if (
          payment.status === "draft" ||
          payment.status === "pending_for_approval" ||
          payment.status === "next_level_approval_required" ||
          payment.status === "entry_approved"
        ) {
          isPending = true;
        }
      }
    }

    return isPending;
  };
  const dateToBeExpired = () => {
    const inputDate = new Date(defaulter?.data?.plan_details?.expiryDate); // Your input date

    const day = inputDate.getDate().toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
    const year = inputDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    console.log(formattedDate);
    return formattedDate;
  };
  useEffect(() => {
    console.log("permission is comingcallRequests", permission);
    checkPermissionOfRequestCreate();

    if (permission && permission.data && permission.data.length > 0) {
      permissionSet(permission.data);
    }
  }, [permission, refresh, callRequests]);

  const permissionSet = async (data) => {
    data.forEach((valueSearch) => {
      if (
        valueSearch &&
        valueSearch.title &&
        valueSearch.title === "Call hold create MSME" &&
        valueSearch.add_edit === 1
      ) {
        setPermissionToCreate(true);
      }
    });
  };

  const checkPermissionOfRequestCreate = async () => {
    if (callRequests?.data?.isValidToCreateNewEntry.length === 0) {
      setCreatePermission(true);
    }

    if (
      callRequests?.data?.isValidToCreateNewEntry &&
      callRequests?.data?.isValidToCreateNewEntry[0]?.request_status &&
      callRequests?.data?.isValidToCreateNewEntry[0]?.request_status !=
        "pending" &&
      callRequests?.data?.isValidToCreateNewEntry[0]?.request_status !=
        "paymentApproved" &&
      callRequests?.data?.isValidToCreateNewEntry[0]?.completed_count <= 1
    ) {
      setCreatePermission(true);
    }
  };
  useEffect(() => {
    if (
      callRequests?.data?.isValidToCreateNewEntry[0]?.request_status ===
        "pending" ||
      callRequests?.data?.isValidToCreateNewEntry[0]?.request_status ===
        "paymentApproved"
    ) {
      setCreatePermission(false);
    }
  }, [callRequests]);

  const getDueDays = () => {
    let days = 0;
    if (
      defaulterData?.consent_signed_date &&
      new Date(defaulterData.consent_signed_date) >
        new Date(defaulterData.verified_date)
    ) {
      days = dateDifference(
        defaulterData.payment_due_date,
        defaulterData.consent_signed_date
      );
    } else {
      days = dateDifference(
        defaulterData.payment_due_date,
        defaulterData.verified_date
      );
    }

    return days;
  };

  const subscriptionHighLight = () => {
    let containerStyle = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: "1px solid rgba(0, 0, 0, 0.125)",
      padding: "10px",
      textAlign: "center",
      borderRadius: "20px",
      color: "white",
    };

    if (
      defaulter?.data?.plan_details?.status === "Active" &&
      defaulter?.data?.plan_details?.days_until_expiration > 30
    ) {
      containerStyle.backgroundColor = "green";
    } else if (
      defaulter?.data?.plan_details?.status === "Active" &&
      defaulter?.data?.plan_details?.days_until_expiration <= 30
    ) {
      containerStyle.backgroundColor = "yellow";
    } else {
      containerStyle.backgroundColor = "#F53134"; // Default color
    }

    return containerStyle;
  };

  return (
    <Layout heading="MSME Defaulter">
      {message && <div className="alert alert-success">{message}</div>}

      {defaulterData && (
        <>
          <Row>
            <Col md={6} className="align-items-stretch">
              <Card>
                <Card.Body>
                  <Card.Title>Defaulter</Card.Title>

                  <h6>{defaulterData.buyer_trade_name}</h6>
                  <div>{defaulterData.buyer_gstn}</div>
                  <div>{defaulterData.buyer_pan}</div>
                  <div>
                    {defaulterData.buyer_email ? defaulterData.buyer_email : ""}
                  </div>
                  <div>
                    {defaulterData.buyer_mobile
                      ? defaulterData.buyer_mobile
                      : defaulterData.buyer_mobile_secondary}
                  </div>
                  <div>{defaulterData.buyer_address || ""}</div>

                  <br />
                  <strong>Extra Details:</strong>
                  <div>
                    {defaulterData.buyer_mobile_secondary
                      ? defaulterData.buyer_mobile_secondary
                      : ""}
                  </div>
                  <div>
                    {defaulterData.defaulters_mobile
                      ? defaulterData.defaulters_mobile
                      : ""}
                  </div>
                  <div>
                    {defaulterData.defaulters_mobile_secondary
                      ? defaulterData.defaulters_mobile_secondary
                      : ""}
                  </div>
                  {defaulter.data.contacts ? (
                    defaulter.data.contacts.map((item, index) => {
                      if (item.contact_type === "mobile")
                        return <div>{item.contact_value}</div>;
                    })
                  ) : (
                    <></>
                  )}

                  <div>
                    {defaulterData.buyer_email_secondary
                      ? defaulterData.buyer_email_secondary
                      : ""}
                  </div>
                  <div>
                    {defaulterData.defaulters_email
                      ? defaulterData.defaulters_email
                      : ""}
                  </div>
                  <div>
                    {defaulterData.defaulters_email_secondary
                      ? defaulterData.defaulters_email_secondary
                      : ""}
                  </div>
                  {defaulter.data.contacts ? (
                    defaulter.data.contacts.map((item, index) => {
                      if (item.contact_type === "email")
                        return <div>{item.contact_value}</div>;
                    })
                  ) : (
                    <></>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="align-items-stretch">
              <Card>
                <Card.Body>
                  <Card.Title
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <strong>SUPPLIER</strong>
                    <strong>
                      Business Status:
                      <span
                        style={{
                          color:
                            defaulterData?.supplier_business_status ===
                            "active"
                              ? "green"
                              : defaulterData?.supplier_business_status ===
                                "inActive"
                              ? "red"
                              : defaulterData?.supplier_business_status ===
                                "expired"
                              ? "red"
                              : "black",
                        }}
                      >
                        {defaulterData?.supplier_business_status}
                      </span>
                    </strong>
                  </Card.Title>

                  <h6>{defaulterData.supplier_trade_name}</h6>
                  <div>{defaulterData.supplier_gstn} </div>
                  <div>{defaulterData.supplier_user_email}</div>
                  <div>{defaulterData.supplier_user_mobile}</div>
                  <br />

                  <strong>Business contacts:</strong>

                  <div>{defaulterData.supplier_email}</div>
                  <div>{defaulterData.supplier_mobile}</div>

                  <div style={subscriptionHighLight()}>
                    <h5 style={{ color: "white" }}>Subscription details</h5>
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>status : </strong>
                      {defaulter?.data?.plan_details?.status}
                    </span>{" "}
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}> Expiry date :</strong>
                      {dateToBeExpired()}
                    </span>{" "}
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>
                        Remaining days:{" "}
                      </strong>
                      {defaulter?.data?.plan_details?.days_until_expiration}
                    </span>
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>
                        {" "}
                        Membership Plan :
                      </strong>
                      {defaulter?.data?.plan_details?.plan_name || "NA"}
                    </span>{" "}
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>
                        {" "}
                        Multiple Factor :
                      </strong>
                      {defaulter?.data?.wallet_multiple_factor || "NA"}
                    </span>{" "}
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>
                        {" "}
                        Verified Defaulter Count :
                      </strong>
                      {defaulter?.data?.defaulterCount
                        ?.verified_defaulter_count || 0}
                    </span>{" "}
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>
                        Inactive Balance:{" "}
                      </strong>
                      {wallet_balance?.data?.totalInActiveBalance || 0}
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Card.Title>Default Status</Card.Title>

                  <br />

                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <td>Default Amount:</td>
                        <td>
                          {currencyFormatter(defaulterData.payment_due_amount)}
                        </td>
                      </tr>

                      <tr>
                        <td>Status</td>
                        <td>{defaulterData.defaulters_status}</td>
                      </tr>
                      <tr>
                        <td>Verified date</td>
                        <td>
                          {format(
                            new Date(defaulterData.verified_date),
                            "yyyy-MM-dd"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>UDIN number</td>
                        <td>{defaulterData.udin_no}</td>
                      </tr>
                      <tr>
                        <td>Legal case</td>
                        <td>{defaulterData.legal_case}</td>
                      </tr>
                      <tr>
                        <td>Due date</td>
                        <td>{defaulterData.payment_due_date}</td>
                      </tr>
                      <tr>
                        <td>No. of days</td>
                        <td>{getDueDays()}</td>
                      </tr>
                      {defaulterData.settlement_amount > 0 && (
                        <tr>
                          <td>Paid amount</td>
                          <td>
                            {currencyFormatter(defaulterData.settlement_amount)}
                          </td>
                        </tr>
                      )}
                      {defaulterData.settlement_amount > 0 && (
                        <tr>
                          <td>Balance amount</td>
                          <td>
                            {defaulterData.payment_due_amount -
                              defaulterData.settlement_amount >
                            0
                              ? currencyFormatter(
                                  defaulterData.payment_due_amount -
                                    defaulterData.settlement_amount
                                )
                              : 0}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Defaulter Documents</Card.Title>

                  <br />
                  {downloadMsg && (
                    <div className="alert alert-success">{downloadMsg}</div>
                  )}
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        {/* <td>Ledger</td> */}

                        <td>
                          {" "}
                          <Button
                            variant="outline-secondary"
                            onClick={downloadHandlerLedger}
                          >
                            Download latest ledger
                          </Button>
                        </td>
                        <td>
                          {" "}
                          <Button
                            variant="outline-secondary"
                            onClick={downloadHandlerAgreement}
                          >
                            Download Agreement Document
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
              {(defaulterData?.defaulters_status === "verified" ||
                defaulterData?.defaulters_status === "partial_settled") && (
                <Row>
                  <Col md={12}>
                    <Card className="px-3 py-3">
                      <Card.Title>Settlement Payment paid to</Card.Title>
                      <Card.Body>
                        <SettlementPaymentPaidTo
                          onPaidToChange={paidTochangeHandler}
                          shouldDisable={isAnyPaymentRequestPending()}
                        />
                        {/* <UIModalDialog isShow={showPayModal} onClose={modalDailogCloseHandler} size="xl" title="Paid to Supplier">
                         <SettlementPaymentAddForm 
                         defaulterData={defaulterData}  />       
                      </UIModalDialog>     */}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
              {(defaulterData?.defaulters_status === "verified" ||
                defaulterData?.defaulters_status === "partial_settled") &&
              permissionToCreate &&
              createPermission ? (
                <Row style={{ marginTop: "20px" }}>
                  <Col md={12}>
                    <Card className="px-3 py-3">
                      <Card.Title>Call request hold</Card.Title>
                      <Card.Body>
                        <CallRequestHold
                          callRequestType={callRequestHoldHandler}
                          // buttonDisable={false}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Row style={{ marginTop: "20px" }}>
                  <Col md={12}>
                    <Card className="px-3 py-3">
                      <Card.Title>Call request hold</Card.Title>
                      {/* <Card.Body> */}
                      {/* <CallRequestHold
                          callRequestType={callRequestHoldHandler}
                          buttonDisable={true}
                        /> */}
                      <h6 style={{ marginTop: "10px", color: "red" }}>
                        {" "}
                        You cannot create a new entry. Either you already have a
                        pending call hold request or you don,t have permission !
                      </h6>
                      {/* </Card.Body> */}
                    </Card>
                  </Col>
                </Row>
              )}

              {
                <Row>
                  <p>
                    <br />
                  </p>
                  <h4>Defaulter contact details</h4>
                  <Col md={12}>
                    <DefaulterContacts
                      defaulterData={defaulter.data}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      contactType={"defaulter_contact"}
                    />
                  </Col>
                </Row>
              }
            </Col>

            <Col md={6}>
              <Card>
                <Card.Body>
                  <Card.Title>Settlement</Card.Title>

                  <div>
                    <SettlementForm
                      defaulterData={defaulterData}
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                    <br />
                  </div>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>Settlement Payments History</Card.Title>

                  <div style={{ width: "100%", overflowX: "scroll" }}>
                    {payments?.data && (
                      <SettlementPaymentList
                        payments={payments}
                        defaulter_case_id={id}
                      />
                    )}
                    <br />
                  </div>
                </Card.Body>
              </Card>
              {permissionToCreate && (
                <Card>
                  <Card.Body>
                    <Card.Title>Call request hold History</Card.Title>

                    <div style={{ width: "100%", overflowX: "scroll" }}>
                      {payments?.data && (
                        <CallRequestHoldList
                          callRequests={callRequests}
                          defaulter_id={
                            defaulter?.data?.defaulter?.defaulter_id
                          }
                          defaulter_case_id={id}
                          source="msme"
                        />
                      )}
                      <br />
                    </div>
                  </Card.Body>
                </Card>
              )}
              <Card>
                <Card.Body>
                  <Card.Title>Supplier contact details</Card.Title>

                  <Row>
                    <Col md={12}>
                      <p>
                        <br />
                      </p>
                      <h4>Supplier contact details</h4>

                      <DefaulterContacts
                        supplier_id={defaulterData.supplier_id}
                        defaulterData={supplier.data}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        contactType={"supplier_contact"}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Layout>
  );
};

import * as axios from "axios";
//import * as miscService from "../services/Misc";
import { Navigate } from "react-router-dom";

axios.interceptors.request.use(
  function (config) {
    //miscService.showpreloader();
    return config;
  },
  function (error) {
    console.log("error", error);
    //miscService.hidepreloader();
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (err) => {
  const error = err.response;
  // if error is 401

  if (error.status === 401) {
    // request for a new token
    return Promise.reject({ status: false, message: "Unauthorized Access!!" });
  }
});

axios.interceptors.response.use(
  function (response) {
    //miscService.hidepreloader();
    return response;
  },
  function (error) {
    if (error && error.message) {
      if (error.message.includes(401)) {
        localStorage.clear();
        return <Navigate to={"/login"} />;
      }
    }
    //miscService.hidepreloader();
    return Promise.reject(error);
  }
);
const post = async (url = "", data, token = false) => {
  console.log("Post URL ", url);
  if (token) {
    token = "Bearer " + token;
  }
  let response = await axios.post(url, data, {
    headers: { Authorization: token },
  });

  return response.data;
};
const put = async (url = "", data, token = false) => {
  if (token) {
    token = "Bearer " + token;
  }

  let response = await axios.put(url, data, {
    headers: { Authorization: token },
  });
  return response.data;
};
const get = async (url = "", token = false) => {
  if (token) {
    token = "Bearer " + token;
  }
  let response = await axios.get(url, { headers: { Authorization: token } });
  return response.data;
};

const deleteData = async (url = "", token = false) => {
  if (token) {
    token = "Bearer " + token;
  }
  let response = await axios.delete(url, { headers: { Authorization: token } });
  return response.data;
};

const downloadFile = async (url = "", data, token = false) => {
  if (token) {
    token = "Bearer " + token;
  }
  axios
    .post(url, data, {
      responseType: "blob",
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
    })
    .then(function (response) {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = data.filename;
      link.click();
    });
};
const downloadFileZip = async (url = "", data, token = false) => {
  if (token) {
    token = "Bearer " + token;
  }
  axios
    .post(url, data, {
      responseType: "blob",
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
    })
    .then(function (response) {
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = data.filename || "Evidence_files.zip";
      link.click();
    });
};
const attachmentUrl = async (url = "", data, token = false) => {
  if (token) {
    token = "Bearer " + token;
  }
  const docResponse = await axios.post(url, data, {
    responseType: "blob",
    headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
  });
  const type = docResponse.headers["content-type"];
  const blob = new Blob([docResponse.data], { type: type, encoding: "UTF-8" });
  return window.URL.createObjectURL(blob);
};

export {
  post,
  get,
  deleteData,
  put,
  downloadFile,
  attachmentUrl,
  downloadFileZip,
};

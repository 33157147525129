import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import PaymentServices from "../../services/payments";
export const supplierWalletBalance = createAsyncThunk(
  "wallet-balance/",
  async (data = null, thunkAPI) => {
    try {
      const result = await PaymentServices.supplierWalletBalance(data);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const supplierHoldWalletBalance = createAsyncThunk(
  "hold-wallet-balance/",
  async (data = null, thunkAPI) => {
    try {
      const result = await PaymentServices.holdWalletBalance(data);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  wallet_balance: null,
  hold_wallet_balance:null
};
const SupplierWalletBalanceSlice = createSlice({
  name: "wallet_balance",
  initialState,
  extraReducers: {
    [supplierWalletBalance.fulfilled]: (state, action) => {
      state.wallet_balance = action.payload;
    },
    [supplierWalletBalance.rejected]: (state, action) => {
      state.wallet_balance = null;
    },
    [supplierHoldWalletBalance.fulfilled]: (state, action) => {
      state.hold_wallet_balance = action.payload;
    },
    [supplierHoldWalletBalance.rejected]: (state, action) => {
      state.hold_wallet_balance = null;
    },
  },
});

export default SupplierWalletBalanceSlice.reducer;

import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import defaultersService from "../../../services/payments";
import CreditqDefaulters from "../../../services/creditqDefaulters/index";
import deleteIcon from "../../../assets/images/delete.png";
import { toast } from "react-toastify";
import { getToken } from "../../../helpers/common";
import { useNavigate } from "react-router-dom";

const RejectModal = (props) => {
  const {
    setIsModalOpen,
    formValues,
    settlementData,
    defaulterID,
    setIsLoading,
  } = props;
  const [fileBase64, setFileBase64] = useState([]);
  const [filename, setFilename] = useState([]);
  const [files, setFiles] = useState([{ id: 1, file: null }]);
  console.log(settlementData);
  console.log(formValues);

  const handleDownloadAll = async () => {
    if (settlementData?.document_paths) {
      const documentPaths = settlementData.document_paths;
      let token = getToken();
      const payload = {
        filepath: documentPaths,
        source: "creditq-defaulter",
      };
      await defaultersService.downloadEvidence(payload, token);
    }
  };

  const addFileInput = () => {
    if (files.length >= 5) {
      toast.error("Maximum of 5 evidence files allowed");
      return;
    }
    const newId = files.length + 1;
    setFiles([...files, { id: newId, file: null }]);
  };

  const removeFileInput = (id, setFieldValue) => {
    const updatedFiles = files.filter((file) => file.id !== id);

    const reindexedFiles = updatedFiles.map((file, index) => ({
      ...file,
      id: index + 1,
    }));

    setFiles(reindexedFiles);
    const updatedFileBase64 = fileBase64.filter((_, index) => index !== id - 1);
    const updatedFilename = filename.filter((_, index) => index !== id - 1);
    setFileBase64(updatedFileBase64);
    setFilename(updatedFilename);

    setFieldValue("evidenceFileName", updatedFilename);
    setFieldValue("evidenceFile", updatedFileBase64);

    setFieldValue(
      "files",
      updatedFiles.map((file) => file.file).filter(Boolean)
    );
  };

  const handleFileChange = (e, id, setFieldValue) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.size <= 2000000) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newFileBase64 = [...fileBase64];
        const newFilename = [...filename];

        const fileIndex = id - 1;
        if (fileIndex < newFileBase64.length) {
          newFileBase64[fileIndex] = reader.result;
          newFilename[fileIndex] = selectedFile.name;
        } else {
          newFileBase64.push(reader.result);
          newFilename.push(selectedFile.name);
        }

        setFileBase64(newFileBase64);
        setFilename(newFilename);

        const updatedFiles = files.map((file) =>
          file.id === id
            ? { ...file, file: selectedFile, base64: reader.result }
            : file
        );
        setFiles(updatedFiles);

        setFieldValue("evidenceFileName", newFilename);
        setFieldValue("evidenceFile", newFileBase64);
        setFieldValue(
          "files",
          updatedFiles.map((file) => file.file).filter(Boolean)
        );
      };
      reader.readAsDataURL(selectedFile);
    } else {
      toast.error("File is too large to get processed");
    }
  };

  const navigate = useNavigate();
  const handleEnteredValues = async (value, formValues, settlementData) => {
    setIsLoading(true);
    console.log("Modal value", value);
    console.log("Form values", formValues);
    const result = await CreditqDefaulters.reject(
      value,
      formValues,
      settlementData
    );
    console.log(result);
    if (result.status === 200) {
      navigate(`/creditq-defaulters/${defaulterID}`);
      toast.success("The settlement request has been rejected");
    } else {
      toast.error("Failed to reject settlement");
    }
    setIsLoading(false);
    setIsModalOpen(false);
  };

  const validationSchema = Yup.object().shape({
    reason: Yup.string().trim().required().label("Reason"),
    files: Yup.array().min(1, "At least one evidence file is required"),
  });

  return (
    <div>
      <h5 style={{ fontWeight: "bold", marginTop: "1rem" }}>
        Are you sure you want to proceed with the rejection?
      </h5>
      {settlementData?.document_paths && (
        <button
          onClick={handleDownloadAll}
          style={{ marginBottom: "1rem" }}
          className="btn btn-theme btn-small mlauto mt20"
        >
          Download All Evidence
        </button>
      )}
      <Formik
        initialValues={{
          evidenceFileName: filename,
          evidenceFile: fileBase64,
          files: [],
          reason: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleEnteredValues(values, formValues, settlementData);
          setSubmitting(false);
          setIsModalOpen(false);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="form-box-mobile">
              <div className="form-box-mobile icon-box userInput">
                <label>Reason</label>
                <Field
                  name="reason"
                  value={values.reason}
                  onChange={(e) => {
                    setFieldValue("reason", e.target.value);
                  }}
                  component="textarea"
                />
                {errors.reason && touched.reason && (
                  <div className="error">{errors.reason}</div>
                )}
              </div>
              <div>
                <label>Evidence</label>
                {files.map((file) => (
                  <div
                    key={file.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginBottom: "1rem",
                    }}
                  >
                    <input
                      type="file"
                      className="file-input"
                      id={`fileUpload${file.id}`}
                      hidden
                      onChange={(e) => {
                        handleFileChange(e, file.id, setFieldValue);
                      }}
                    />
                    <label
                      htmlFor={`fileUpload${file.id}`}
                      style={{
                        border: "1px solid #007bff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "5px",
                        width: "100%",
                      }}
                      className="btn btn-upload"
                    >
                      {file.file ? file.file.name : "Choose File"}
                    </label>
                    {files.length > 1 && (
                      <span
                        onClick={() => removeFileInput(file.id, setFieldValue)}
                        style={{
                          marginLeft: "10px",
                          marginTop: "5px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </span>
                    )}
                  </div>
                ))}
                {errors.files && touched.files && (
                  <span className="error">{errors.files}</span>
                )}
                <div
                  style={{
                    color: "#007bff",
                    cursor: "pointer",
                  }}
                  onClick={addFileInput}
                >
                  Click to add more Evidence
                </div>
              </div>
              <div>
                <button
                  className="btn btn-theme btn-small mlauto mt20"
                  type="submit"
                >
                  Reject
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RejectModal;

import React from "react";
import * as S from "./style";
import * as Icon from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import { getPermission } from "../../store/slices/defaulter";
import { useSelector, useDispatch } from "react-redux";
import { clearMessage } from "../../store/slices/message";
import { useEffect } from "react";
import { checkPermission } from "../../helpers/common";
const CustomNavigation = (props) => {
  const location = useLocation();

  const pathname = location.pathname;

  let links = [
    {
      link: "/app",
      icon: () => {
        return <Icon.Speedometer style={IconStyle} />;
      },

      text: "Dashboard",
    },
    {
      link: "/defaulters",
      icon: () => {
        return <Icon.People style={IconStyle} />;
      },
      text: "MSME Defaulters",
    },
    // {
    //   link: "/settlement/requests",
    //   icon: () => {
    //     return <Icon.People style={IconStyle} />;
    //   },
    //   text: "Settlement Requests",
    // },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getPermission());
  }, []);

  const { permission } = useSelector((state) => state.defaulterSlice);

  if (
    checkPermission(permission?.data, "CreditQ Defaulters Page", "listView") ===
    1
  ) {
    links.push({
      link: "/creditq-defaulters",
      icon: () => {
        return <Icon.People style={IconStyle} />;
      },
      text: "CreditQ Defaulters",
    });
  }
  if (
    checkPermission(
      permission?.data,
      "Verify Defaulter Contact",
      "listView"
    ) === 1
  ) {
    links.push({
      link: "/defaulters-contacts",
      icon: () => {
        return <Icon.ChevronContract style={IconStyle} />;
      },
      text: "Defaulter Contacts",
    });
  }
  if (
    checkPermission(
      permission?.data,
      "Relationship Manager Change Request",
      "listView"
    ) === 1
  ) {
    links.push({
      link: "/relationship-manager-change-request",
      icon: () => {
        return <Icon.EnvelopePlus style={IconStyle} />;
      },
      text: "RM Change Request",
    });
  }
  if (
    checkPermission(
      permission?.data,
      "Service Agreement List Msme",
      "listView"
    ) === 1
  )
   {
    links.push({
      link: "/service_agreement",
      icon: () => {
        return <Icon.EnvelopePlus style={IconStyle} />;
      },
      text: "Service Agreement",
    });
  }

  return (
    <>
      {props.openSidebar && (
        <S.NavWrapper
          activeKey="/app"
          onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
        >
          {links.map((linkObj, index) => (
            <S.NavItem key={`link-${index}`}>
              <S.NavLink
                to={linkObj.link}
                className={
                  linkObj.link.toString() === pathname.toString()
                    ? "activeClass"
                    : ""
                }
              >
                {linkObj.icon()}
                {linkObj.text}
              </S.NavLink>
            </S.NavItem>
          ))}
        </S.NavWrapper>
      )}
    </>
  );
};

const IconStyle = {
  width: "16px",
  height: "16px",
  marginRight: "10px",
  display: "inline",
};

export default CustomNavigation;

import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getDefaulters = async (page, filterData) => {
  try {
    let data = {};
    let token = getToken();
    data = { page, filterData };
    console.log("data", data);
    let result = await apiService.post(
      API_URL.GET_CREDITQ_DEFAULTERS,
      data,
      token
    );
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getDefaulter = async (_, data) => {
  try {
    let token = getToken();
    let result = await apiService.post(
      API_URL.GET_CREDITQ_DEFAULTER,
      data,
      token
    );
    return result;
    // }
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const downloadPaymentProof = async (data, token) => {
  let result = apiService.downloadFile(
    API_URL.PAYMENT_PROOF_DOWNLOAD_CREDITQ_DEFAULTER,
    data,
    token
  );
  return result;
};
const downloadPaymentProofZip = async (data, token) => {
  let result = apiService.downloadFileZip(
    API_URL.PAYMENT_PROOF_DOWNLOAD_CREDITQ_DEFAULTER_ZIP,
    data,
    token
  );
  return result;
};
const emailSupplier = async (value, formValues, settlementData) => {
  try {
    let token = getToken();
    let data = { value, formValues, settlementData };
    let result = await apiService.post(API_URL.EMAIL_SUPPLIER, data, token);
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const emailAuthority = async (value, formValues, settlementData) => {
  try {
    let token = getToken();
    let data = { value, formValues, settlementData };
    let result = await apiService.post(API_URL.EMAIL_AUTHORITY, data, token);
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const reject = async (value, formValues, settlementData) => {
  try {
    let token = getToken();
    let data = { value, formValues, settlementData };
    let result = await apiService.post(API_URL.REJECT, data, token);
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const CreditqDefaulters = {
  getDefaulters,
  downloadPaymentProof,
  getDefaulter,
  emailSupplier,
  emailAuthority,
  reject,
  downloadPaymentProofZip,
};

export default CreditqDefaulters;

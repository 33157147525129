import React, { useState } from "react";
import Modal from "react-modal";
import { Document, Page } from "react-pdf";
import customStyles from "./Styles";
function DocumentViewer(props) {
  const { setIsOpen, isOpen, documentUrl, docTitle } = props;
  const isFrame = true;
  const [pagesNumber, setPagesNumber] = useState([]);

  function onDocumentLoadSuccess({ numPages }) {
    let pageArray = [];
    for (let i = 1; i <= numPages; i++) {
      pageArray.push(i);
    }
    setPagesNumber(pageArray);
  }

  return (
    <Modal isOpen={isOpen} style={customStyles} contentLabel="Cir view">
      <div style={{ textAlign: "right" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
          style={{
            color: "#ea4335",
            width: "25px",
            height: "auto",
            cursor: "pointer",
            stroke: "#ea4335",
          }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>

      {documentUrl && (
        <div>
          {isFrame ? (
            <iframe
              title={docTitle}
              className="docFrame"
              style={{ width: "50vw", height: "75vh" }}
              src={documentUrl}
            ></iframe>
          ) : (
            <Document
              noData={"No invoice found"}
              file={documentUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {pagesNumber.map((page, index) => (
                <Page pageNumber={page} key={index} />
              ))}
            </Document>
          )}
        </div>
      )}
    </Modal>
  );
}
export default DocumentViewer;

import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
//import Button from "../../components/Button";
import * as S from "../style";
import { Row, Col, Button } from "react-bootstrap";
import validationSchema from "../Validation/searchFormSchema";
import AlertErrorMessage from "../../../components/Alert/Error";
import { smartSearchActions } from "../../../store/slices/smartSearch/smartSearch";

export default function SmartSearchForm(props) {
  const { onSubmit, setFilterData } = props;
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedSearchFor, setSelectedSearchFor] = useState(null);
  const initialValues = {
    mobile: "",
    email: "",
    gstPan: "",
    tradeName: "",
    legalName: "",
    userName: "",
    searchFor: "all",
  };
  const dispatch = useDispatch();

  const handleFilter = (formValue) => {
    console.log("formValue@@@@@@", formValue);
    dispatch(smartSearchActions.resetSmartSearch(""));
    if (
      !formValue?.mobile &&
      !formValue?.email &&
      !formValue?.gstPan &&
      !formValue?.tradeName &&
      !formValue?.legalName &&
      !formValue?.userName
    ) {
      setErrorMessage("At least one field must be provided");
      return false;
    } else {
      if (errorMessage) {
        setErrorMessage(null);
      }
    }
    console.log("formValue@@@@@@2", formValue);
    onSubmit(formValue);
  };

  const handleReset = () => {
    props.setLoading(false);
    setFilterData(null);
    dispatch(smartSearchActions.resetSmartSearch(""));
  };
  const searchForChangeHandler = (event, setFieldValue) => {
    const selectedValue = event.target.value;
    setSelectedSearchFor(selectedValue);
    setFieldValue("searchFor", selectedValue);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleFilter}
      onReset={handleReset}
    >
      {({ setFieldValue }) => (
        <Form>
          <Row className="mb-3" style={{ maxWidth: "300px" }}>
            <Col xl={12} md={4} lg={6}>
              <label>Search In</label>
              <Field
                name="searchFor"
                placeholder="Search for"
                as="select"
                className="form-select"
                size="sm"
                onChange={(e) => searchForChangeHandler(e, setFieldValue)}
              >
                <option value="all">All</option>
                <option value="user">User</option>
                <option value="business">Business</option>
                <option value="defaulter">Defaulter</option>
              </Field>
            </Col>
          </Row>
          <Row className="mb-3 gy-3">
            <Col md={3}>
              <div className="form-floating ">
                <Field
                  name="mobile"
                  type="text"
                  className="form-control"
                  placeholder="Enter mobile number"
                  size="sm"
                />
                <ErrorMessage
                  name="mobile"
                  component="span"
                  className="errorText"
                />
                <S.Label htmlFor="mobile">Mobile</S.Label>
              </div>
            </Col>
            <Col md={3}>
              <div className="form-floating ">
                <Field
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder="Enter email"
                  size="sm"
                />
                <ErrorMessage
                  name="email"
                  component="span"
                  className="errorText"
                />
                <S.Label htmlFor="email">Email</S.Label>
              </div>
            </Col>
            <Col
              style={{
                display: selectedSearchFor === "user" ? "none" : "",
              }}
              md={3}
            >
              <div className="form-floating">
                <Field
                  name="gstPan"
                  type="text"
                  className="form-control"
                  placeholder="Enter gst/pan"
                  size="sm"
                />
                <ErrorMessage
                  name="gstPan"
                  component="span"
                  className="errorText"
                />
                <S.Label htmlFor="gstPan">Gst/PAN</S.Label>
              </div>
            </Col>

            <Col
              style={{
                display: selectedSearchFor === "user" ? "none" : "",
              }}
              md={3}
            >
              <div className="form-floating ">
                <Field
                  name="tradeName"
                  type="text"
                  className="form-control"
                  placeholder="Enter trade name"
                  size="sm"
                />
                <ErrorMessage
                  name="tradeName"
                  component="span"
                  className="errorText"
                />
                <S.Label htmlFor="tradeName">Trade Name</S.Label>
              </div>
            </Col>
            <Col
              style={{
                display: selectedSearchFor === "user" ? "none" : "",
              }}
              md={3}
            >
              <div className="form-floating ">
                <Field
                  name="legalName"
                  type="text"
                  className="form-control"
                  placeholder="Enter legal name"
                  size="sm"
                />
                <ErrorMessage
                  name="legalName"
                  component="span"
                  className="errorText"
                />
                <S.Label htmlFor="legalName">Legal Name</S.Label>
              </div>
            </Col>
            <Col
              md={3}
              style={{ display: selectedSearchFor === "user" ? "" : "none" }}
            >
              <div className="form-floating ">
                <Field
                  name="userName"
                  type="text"
                  className="form-control"
                  placeholder="Enter user name"
                  size="sm"
                />
                <ErrorMessage
                  name="userName"
                  component="span"
                  className="errorText"
                />
                <S.Label htmlFor="userName">User Name</S.Label>
              </div>
            </Col>
            <Col md={3}>
              <div className="form-floating ">
                <Button type="submit" size="sm">
                  Search
                </Button>
                <Button
                  as="input"
                  type="reset"
                  variant="light"
                  value="Reset"
                  xs={4}
                  md={1}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <AlertErrorMessage>{errorMessage}</AlertErrorMessage>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

import React from "react";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import { currencyFormatter } from "../../../helpers/common";
import smartSearchService from "../../../services/smartSearch";

export const BlockSettlementHistory = (props) => {
  const { settlementHistory } = props;

  //Heading of the table
  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Settlement Type</th>
          <th>Settlement Entry Date </th>
          <th>Payment Received Date </th>
          <th>Amount</th>
          <th>Evidence</th>
        </tr>
      </thead>
    );
  };

  console.log("additionalContanctList@@@@@result", settlementHistory);

  const _list = () => {
    if (settlementHistory?.length < 1) {
      return (
        <tr>
          <td colSpan="6">No record found!!</td>
        </tr>
      );
    }
    return settlementHistory.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{index + 1}</td>
          <td>{item?.type ? item.type : ""}</td>
          <td>
            {item?.payment_date
              ? format(new Date(item?.payment_date), "dd-MM-yyyy")
              : ""}
          </td>
          <td>
            {item?.settlement_entry_date
              ? format(new Date(item?.settlement_entry_date), "dd-MM-yyyy")
              : item?.date_modified
              ? format(new Date(item?.date_modified), "dd-MM-yyyy")
              : ""}
          </td>
          <td>{currencyFormatter(item?.amount ? item?.amount : "0")}</td>
          <td>
            {item?.evidence_filename ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
                style={{
                  width: "22px",
                  height: "auto",
                  cursor: "pointer",
                }}
                onClick={() => documentDownloadHandler(item?.evidence_filename)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
            ) : (
              "N/A"
            )}
          </td>
        </tr>
      );
    });
  };

  const documentDownloadHandler = (filePath) => {
    // let fileExtension = filePath.split(".").pop();
    let postData = { fileName: filePath, filename: filePath };
    console.log("payment-proof-download@@@@payload", postData);
    smartSearchService.downloadSettlementDoc(postData);
  };

  return (
    <>
      <div>
        <Table striped bordered hover>
          {_tableHeader()}
          <tbody>{_list()}</tbody>
        </Table>
      </div>
    </>
  );
};

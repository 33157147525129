import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Table } from "react-bootstrap";

import { currencyFormatter } from "../../../helpers/common";

export const BlockReportedByList = (props) => {
  const { reportedByList } = props;
  const navigate = useNavigate();
  //Heading of the table
  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Trade Name</th>
          <th>Legal Name</th>
          <th>GST No/PAN No</th>
          <th>Due Date</th>
          <th>Status</th>
          <th>Verified Date</th>
          <th>Reported Amount</th>
          <th>Settled Amount</th>
          <th>Balance Amount</th>
          <th>Write Off</th>
          <th>State & City</th>
          <th>Action</th>
        </tr>
      </thead>
    );
  };

  console.log("smartSearch@@@@@reported by", reportedByList);

  const _list = () => {
    if (!reportedByList || reportedByList.length < 1) {
      return (
        <tr>
          <td colSpan="13">No record found!!</td>
        </tr>
      );
    }
    return reportedByList.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{index + 1}</td>
          <td>{item?.trade_name ? item.trade_name : ""}</td>
          <td>{item?.company_name ? item.company_name : ""}</td>
          <td>{item?.gst_no ? item?.gst_no : item?.pan_no}</td>
          <td>
            {item?.payment_due_date
              ? format(new Date(item.payment_due_date), "dd-MM-yyyy")
              : ""}
          </td>
          <td>{item?.defaulters_status}</td>
          <td>
            {item?.verified_date
              ? format(new Date(item?.verified_date), "dd-MM-yyyy")
              : ""}
          </td>
          <td>
            {currencyFormatter(
              item?.payment_due_amount ? item?.payment_due_amount : "0"
            )}
          </td>
          <td>
            {item?.settlement_amount
              ? currencyFormatter(item.settlement_amount)
              : currencyFormatter("0")}
          </td>
          <td>
            {item?.balance_amount
              ? currencyFormatter(item.balance_amount)
              : currencyFormatter("0")}
          </td>
          <td>
            {item?.writeoff_amount
              ? currencyFormatter(item.writeoff_amount)
              : currencyFormatter("0")}
          </td>
          <td>
            {item?.state_name && item?.city_name
              ? `${item?.state_name} / ${item?.city_name}`
              : item?.state_name
              ? item?.state_name
              : item?.city_name
              ? item?.city_name
              : ""}
          </td>
          <td>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 cursor-pointer"
              style={{ width: "25px", height: "auto", cursor: "pointer" }}
              onClick={() => detailPageHandler(item.id)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          </td>
        </tr>
      );
    });
  };

  const detailPageHandler = (id) => {
    const currentTimeStamp = new Date().getTime();
    const encodedId = btoa(`${currentTimeStamp}-${id}`);
    navigate(`/smartSearch/defaulterDetail/${encodedId}`);
    console.log("detailPageHandler@@@@@@", encodedId);
  };

  return (
    <>
      <div
        style={{
          maxWidth: "100%",
          "overflow-x": "scroll",
          "white-space": "nowrap",
        }}
      >
        <Table striped bordered hover>
          {_tableHeader()}
          <tbody>{_list()}</tbody>
        </Table>
      </div>
    </>
  );
};

import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getListofPending = async (page, filterData) => {
  try {
    let data = {};

    let token = getToken();

    data = { page, filterData };
    console.log("data", data);
    let result = await apiService.post(
      API_URL.GET_PENDING_SERVICE_AGREEMENT,
      data,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const changeStatusofAgreement = async (payload) => {
  try {
    let token = getToken();
    console.log("user/getBusinesses@@@@service - payload", payload);
    let result = await apiService.post(
      API_URL.CHANGE_STATUS_SERVICE_AGREEMENT,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const downloadAgreement = async (payload) => {
    try {
      let token = getToken();
      let result = await apiService.downloadFile(
        API_URL.DOWNLOAD_SERVICE_AGREEMENT,
        payload,
        token
      );
      return result;
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  };

  const updateAgreementStatus = async (payload) => {
    try {
      let token = getToken();
      let result = await apiService.post(
        API_URL.UPDATE_SERVICE_AGREEMENT,
        payload,
        token
      );
      return result;
    } catch (e) {
      return { status: false, data: {}, message: e.message };
    }
  };
const serviceAgreement = {
  getListofPending,
  changeStatusofAgreement,
  downloadAgreement,
  updateAgreementStatus
};

export default serviceAgreement;

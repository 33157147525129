import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Button } from 'react-bootstrap';
import * as S from '../Defaulters/style'; 

const FilterForm = ({ setLoading, setFilterData, defaultFilterData, fieldsConfig }) => {
  const initialValues = fieldsConfig.reduce((values, field) => {
    values[field.name] = ''; 
    return values;
  }, {});


  const validationSchema = Yup.object(
    fieldsConfig.reduce((schema, field) => {
      if (field.required) {
        schema[field.name] = Yup.string().required(`${field.label} is required`);
      } else {
        schema[field.name] = Yup.string(); 
      }
      return schema;
    }, {})
  );

  const handleFilter = (values) => {
    const isFilterApplied = Object.values(values).some(
        (val) => val && val.trim() !== "" && val !== "DoNotShowList"
      );
    
      if (isFilterApplied) {
        setLoading(true);
        setFilterData(values); 
      } else {
        
      }
  };

  
  const handleReset = () => {
    setLoading(false);
    setFilterData(defaultFilterData);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFilter}
      onReset={handleReset}
    >
      {() => (
        <Form>
          <Row className="mb-3">
            {fieldsConfig.map((field) => (
              <Col key={field.name} md={4}>
                <div className="form-floating">
                  <Field
                    name={field.name}
                    type={field.type || 'text'} 
                    className="form-control"
                    placeholder={`Enter ${field.label}`}
                    size="sm"
                  />
                  <ErrorMessage name={field.name} component="span" className="errorText" />
                  <S.Label htmlFor={field.name}>{field.label}</S.Label>
                </div>
              </Col>
            ))}
          </Row>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" value="Filter" xs={4} md={1}>Filter</Button>
            <Button
              type="reset"
              variant="light"
              value="Reset"
              xs={4}
              md={1}
              style={{ marginLeft: '10px' }}
            >
              Reset
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FilterForm;

//import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../pages/NotFound";
import RequireAuth from "../components/RequireAuth";
//const Login = lazy(() => import("../pages/auth/Login"));
import Login from "../pages/auth/Login";
//const Home = lazy(() => import("../pages/Home"));
import Home from "../pages/Home";
import Defaulters from "../pages/Defaulters";
import Defaulter from "../pages/Defaulter";
import CreditqDefaulter from "../pages/Creditq-Defaulter/CreditqDefaulter";
import SettlementRequests from "../pages/SettlementRequests";
import PaymentCreate from "../pages/Payment/Create";
import PaymentEdit from "../pages/Payment/Edit";
import CallRequestCreate from "../pages/CallRequestHold/create";
import CallRequestEdit from "../pages/CallRequestHold/Edit";
import DefaulterContacts from "../pages/Defaulter-contacts/DefaulterContacts";
import { getPermission } from "../store/slices/defaulter";
import { useSelector, useDispatch } from "react-redux";
import { clearMessage } from "../store/slices/message";
import { useEffect } from "react";
import { checkPermission } from "../helpers/common";
import CreditqDefaulters from "../pages/Creditq-Defaulters/CreditqDefaulters";
import CQPaymentCreate from "../pages/CreditQ/Payments/create";
import RelationshipManagerChangeRequest from "../pages/CreditQ/RelationshipManagerChangeRequest";
import SmartSearchBusinessDetail from "../pages/SmartSearch/BusinessDetail";
import SmartSearchDefaulterDetail from "../pages/SmartSearch/DefaulterDetail";
import SmartSearchUserDetail from "../pages/SmartSearch/UserDetail";
import { ServiceAgreement } from "../pages/Agreement/ServiceAgreement";

export function RenderRoutes({ routes }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearMessage());
    dispatch(getPermission());
  }, []);

  const { permission } = useSelector((state) => state.defaulterSlice);
  console.log("permission---1", permission);
  useEffect(() => {
    if (permission?.data.length > 0) {
      getUserDetails(permission?.data, permission?.userStatus);
    }
  }, [permission]);

  const getUserDetails = async (permissions, userStatus) => {
    const hasRestrictedAccess =
      permissions &&
      permissions.some(
        (perm) => perm.title == "Settlement executive" && perm.add_edit == 0
      );
    console.log("hasRestrictedAccess", hasRestrictedAccess);
    if (hasRestrictedAccess || userStatus == 0) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
  };

  return (
    <Routes>
      <Route
        key={"ROOT"}
        path={"/"}
        exact={true}
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route key={"LOGIN"} path={"/login"} exact={true} element={<Login />} />
      <Route
        key={"APP"}
        path={"/app"}
        exact={false}
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      {checkPermission(permission?.data, "MSME Defaulters Page", "listView") ===
        1 && (
        <Route
          key={"DEFAULTERS"}
          path={"/defaulters"}
          exact={true}
          element={
            <RequireAuth>
              <Defaulters />
            </RequireAuth>
          }
        />
      )}
      {checkPermission(
        permission?.data,
        "Verify Defaulter Contact",
        "listView"
      ) === 1 && (
        <Route
          key={"DEFAULTERS"}
          path={"/defaulters-contacts"}
          exact={true}
          element={
            <RequireAuth>
              <DefaulterContacts />
            </RequireAuth>
          }
        />
      )}
      {checkPermission(
        permission?.data,
        "CreditQ Defaulters Page",
        "listView"
      ) === 1 && (
        <Route
          key={"CREDITQ DEFAULTERS"}
          path={"/creditq-defaulters"}
          exact={true}
          element={
            <RequireAuth>
              <CreditqDefaulters />
            </RequireAuth>
          }
        />
      )}
      <Route
        key={"DEFAULTER"}
        path={"/defaulter/:id"}
        exact={true}
        element={
          <RequireAuth>
            <Defaulter />
          </RequireAuth>
        }
      />

      <Route
        key={"SETTLEMENTREQUESTS"}
        path={"/settlement/requests"}
        exact={true}
        element={
          <RequireAuth>
            <SettlementRequests />
          </RequireAuth>
        }
      />
      <Route
        key={"PAYMENTCREATE"}
        path={"/payment/:paid_to/:defaulter_id/create"}
        exact={true}
        element={
          <RequireAuth>
            <PaymentCreate />
          </RequireAuth>
        }
      />
      <Route
        key={"PAYMENTEDITVIEW"}
        path={"/payment/:paid_to/:defaulter_id/edit/:payment_id"}
        exact={true}
        element={
          <RequireAuth>
            <PaymentEdit />
          </RequireAuth>
        }
      />
      {checkPermission(
        permission?.data,
        "Call hold create MSME",
        "add_edit"
      ) === 1 && (
        <Route
          key={"CALLHOLDCREATE"}
          path={"/callHold/:holdType/:defaulter_id/create/:source"}
          exact={true}
          element={
            <RequireAuth>
              <CallRequestCreate />
            </RequireAuth>
          }
        />
      )}

      <Route
        key={"CALLHOLDEDITVIEW"}
        path={
          "/holdRequest/:holdType/:defaulter_case_id/edit/:callRequestId/:source"
        }
        exact={true}
        element={
          <RequireAuth>
            <CallRequestEdit />
          </RequireAuth>
        }
      />
      <Route
        key={"CREDITQ-DEFAULTER"}
        path={"/creditq-defaulters/:id"}
        exact={true}
        element={
          <RequireAuth>
            <CreditqDefaulter />
          </RequireAuth>
        }
      />

      {checkPermission(
        permission?.data,
        "CreditQ Defaulters Settlement",
        "add_edit"
      ) === 1 && (
        <Route
          key={"PAYMENTTYPE"}
          path={"creditq/defaulter/payment/:type/:defaulter_id/create"}
          exact={true}
          element={
            <RequireAuth>
              <CQPaymentCreate />
            </RequireAuth>
          }
        />
      )}
      {checkPermission(
        permission?.data,
        "Relationship Manager Change Request",
        "listView"
      ) === 1 && (
        <Route
          key={"RMChangeRequest"}
          path={"/relationship-manager-change-request"}
          exact={true}
          element={
            <RequireAuth>
              <RelationshipManagerChangeRequest />
            </RequireAuth>
          }
        />
      )}

      <Route
        key={"SMARTSEARCH_USERDETAIL"}
        path={"smartSearch/userDetail/:search"}
        exact={true}
        element={
          <RequireAuth>
            <SmartSearchUserDetail />
          </RequireAuth>
        }
      />
      <Route
        key={"SMARTSEARCH_BUSINESSDETAIL"}
        path={"smartSearch/businessDetail/:search"}
        exact={true}
        element={
          <RequireAuth>
            <SmartSearchBusinessDetail />
          </RequireAuth>
        }
      />
      <Route
        key={"SMARTSEARCH_DEFAULTERDETAIL"}
        path={"smartSearch/defaulterDetail/:search"}
        exact={true}
        element={
          <RequireAuth>
            <SmartSearchDefaulterDetail />
          </RequireAuth>
        }
      />
      {checkPermission(
        permission?.data,
        "Service Agreement List Msme",
        "listView"
      ) === 1 && (
        <Route
          key={"SERVICEAGREEMENTS"}
          path={"/service_agreement"}
          exact={true}
          element={
            <RequireAuth>
              <ServiceAgreement />
            </RequireAuth>
          }
        />
        )}
      <Route exact={true} element={<NotFound />} path="*" />
    </Routes>
  );
}

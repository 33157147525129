import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import { toast } from "react-toastify";
import smartSearchService from "../../../services/smartSearch";

export const getUsers = createAsyncThunk(
  "smartSearch/getUsers",
  async (payload, thunkAPI) => {
    try {
      console.log("smartSearch/getUsers@@@@payload", payload);
      const result = await smartSearchService.getUsers(payload);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(
          setMessage(result?.message ? result?.message : "An error occurred")
        );
        toast.error(result?.message);
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBusinesses = createAsyncThunk(
  "smartSearch/getBusinesses",
  async (payload, thunkAPI) => {
    try {
      console.log("smartSearch/getBusinesses@@@@payload", payload);
      const result = await smartSearchService.getBusinesses(payload);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(
          setMessage(result?.message ? result?.message : "An error occurred")
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDefaulters = createAsyncThunk(
  "smartSearch/getDefaulters",
  async (payload, thunkAPI) => {
    try {
      console.log("smartSearch/getDefaulters@@@@payload", payload);
      const result = await smartSearchService.getDefaulters(payload);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(
          setMessage(result?.message ? result?.message : "An error occurred")
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  smartSearch: {
    users: null,
    defaulters: null,
    businesses: null,
  },
};

const smartSearchSlice = createSlice({
  name: "smartSearch",
  initialState,
  reducers: {
    resetSmartSearch(state, _) {
      state.smartSearch = {
        users: null,
        businesses: null,
        defaulters: null,
      };
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@getUsers-fulfilled",
        action?.payload?.data
      );
      state.smartSearch.users = action.payload;
      //return { ...state, defaulters: action.payload.data };
    },
    [getUsers.rejected]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@getUsers-rejected",
        action?.payload?.data
      );
      state.smartSearch = {
        users: null,
      };
      //return { ...state, defaulters: [] };
    },

    [getBusinesses.fulfilled]: (state, action) => {
      console.log("getBusinesses.fulfilled", action);
      console.log(
        "smartSearchSlice - @@@@@@getBusinesses-fulfilled",
        action.payload.data
      );
      state.smartSearch.businesses = action.payload;
      //return { ...state, defaulters: action.payload.data };
    },
    [getBusinesses.rejected]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@getBusinesses-rejected",
        action.payload.data
      );
      state.smartSearch = {
        businesses: null,
      };
      //return { ...state, defaulters: [] };
    },

    [getDefaulters.fulfilled]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@getDefaulters-fulfilled",
        action.payload.data
      );
      state.smartSearch.defaulters = action.payload;
      //return { ...state, defaulters: action.payload.data };
    },
    [getDefaulters.rejected]: (state, action) => {
      console.log(
        "smartSearchSlice - @@@@@@getDefaulters-rejected",
        action.payload.data
      );
      state.smartSearch = {
        defaulters: null,
      };
      //return { ...state, defaulters: [] };
    },
  },
});
export const smartSearchActions = smartSearchSlice.actions;
export default smartSearchSlice.reducer;

import * as API_URL from "../../constants/api";
import * as apiService from "../api";
import { getToken } from "../../helpers/common";

const getUsers = async (payload) => {
  try {
    let token = getToken();
    console.log("user/getUsers@@@@service - payload", payload);
    let result = await apiService.post(
      API_URL.GET_SMART_SEARCH_USERS,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getBusinesses = async (payload) => {
  try {
    let token = getToken();
    console.log("user/getBusinesses@@@@service - payload", payload);
    let result = await apiService.post(
      API_URL.GET_SMART_SEARCH_BUSINESSES,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getDefaulters = async (payload) => {
  try {
    let token = getToken();
    console.log("user/getBusinesses@@@@service - payload", payload);
    let result = await apiService.post(
      API_URL.GET_SMART_SEARCH_DEFAULTERS,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getUserDetail = async (payload) => {
  try {
    let token = getToken();
    console.log("user/getUserDetail@@@@service - payload", payload);
    let result = await apiService.post(
      API_URL.GET_SMART_SEARCH_USER_DETAIL,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getBusinessDetail = async (payload) => {
  try {
    let token = getToken();
    console.log("user/getUserDetail@@@@service - payload", payload);
    let result = await apiService.post(
      API_URL.GET_SMART_SEARCH_BUSINESS_DETAIL,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getDefaulterDetail = async (payload) => {
  try {
    let token = getToken();
    console.log("user/getUserDetail@@@@service - payload", payload);
    let result = await apiService.post(
      API_URL.GET_SMART_SEARCH_DEFAULTER_DETAIL,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getAdditionalContactLog = async (payload) => {
  try {
    let token = getToken();
    console.log("getAdditionalContactLog@@@@service - payload", payload);
    let result = await apiService.post(
      API_URL.GET_SMART_SEARCH_ADDITIONAL_CONTACT_LOG,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const getBusinessServiceAgreementList = async (payload) => {
  try {
    let token = getToken();
    console.log(
      "getBusinessServiceAgreementList@@@@service - payload",
      payload
    );
    let result = await apiService.post(
      API_URL.GET_BUSINESS_SERVICE_AGREEMENT_LIST,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const downloadContactEvidence = async (payload) => {
  try {
    let token = getToken();
    console.log("downloadContactEvidence@@@@service - payload", payload);
    let result = await apiService.downloadFile(
      API_URL.DOWNLOAD_DEFAULTER_CONTACT_EVIDENCE,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const downloadEvidenceFile = async (payload) => {
  try {
    let token = getToken();
    console.log("downloadEvidenceFile@@@@service - payload", payload);
    let result = await apiService.attachmentUrl(
      API_URL.DOWNLOAD_EVIDENCE_FILE,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const downloadDefaulterDoc = async (payload) => {
  try {
    let token = getToken();
    console.log("downloadEvidenceFile@@@@service - payload", payload);
    let result = await apiService.downloadFile(
      API_URL.DOWNLOAD_DEFAULTER_DOC,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const downloadSettlementDoc = async (payload) => {
  try {
    let token = getToken();
    console.log("downloadSettlementDoc@@@@service - payload", payload);
    let result = await apiService.downloadFile(
      API_URL.DOWNLOAD_SETTLEMENT_DOC,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};
const getActivityJourneyLogs = async (payload) => {
  try {
    let token = getToken();
    console.log("getActivityJourneyLogs@@@@service - payload", payload);
    let result = await apiService.post(
      API_URL.GET_ACTIVITY_JOURNEY_LOGS,
      payload,
      token
    );
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const smartSearchService = {
  getUsers,
  getBusinesses,
  getDefaulters,
  getUserDetail,
  getBusinessDetail,
  getDefaulterDetail,
  getAdditionalContactLog,
  downloadContactEvidence,
  downloadEvidenceFile,
  downloadDefaulterDoc,
  downloadSettlementDoc,
  getBusinessServiceAgreementList,
  getActivityJourneyLogs,
};

export default smartSearchService;

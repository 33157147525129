import React from "react";
import { Table } from "react-bootstrap";

export const BlockCallHoldRequestList = (props) => {
  const { callHoldRequests } = props;

  //Heading of the table
  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.No</th>
          <th>Source</th>
          <th>Hold From</th>
          <th>Hold To</th>
          <th>Hold Days</th>
          <th>Points Holded</th>
        </tr>
      </thead>
    );
  };

  console.log("callHoldRequestList@@@@@result", callHoldRequests);

  const _list = () => {
    if (!callHoldRequests || callHoldRequests.length === 0) {
      return (
        <tr>
          <td colSpan="6">No record found!!</td>
        </tr>
      );
    }
    return callHoldRequests.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{index + 1}</td>
          <td>
            {item.type_for_hold === "WALLET_POINTS_HOLD" ? "Customer" : "Admin"}
          </td>
          <td>{item.hold_from}</td>
          <td>{item.hold_to}</td>
          <td>{item.hold_days}</td>
          <td>{item.hold_amount}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <div>
        <Table striped bordered hover>
          {_tableHeader()}
          <tbody>{_list()}</tbody>
        </Table>
      </div>
    </>
  );
};
